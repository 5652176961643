//import packages
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';


//import components
import SecondaryMenu from "../../components/SecondaryMenu.component";
import Footer from "../../components/Footer.component";

export default function Privacy() {
  const { t, i18n } = useTranslation();
  const [lang, setLang] = useState();

  useEffect(() => {
    document.title = "Privacy"
    setLang(i18n.language);
  }, [i18n.language]);

  return (
    <div className="bg-grey-100">
      <SecondaryMenu />
      <div className="max-w-6xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        {lang == "nl" ?
          <div className="font-extralight">
            <h1 className="text-5xl font-semibold pb-5">Privacy</h1>
            
            <h2 className="text-lg font-semibold mt-5 mb-3">I. PRIVACY BELEID: inleiding</h2>
            <p>
            Dit privacy beleid betreft de omgang van Upgrade Estate NV met de persoonsgegevens van haar klanten, natuurlijke personen, en alle andere natuurlijke personen die met Upgrade Estate NV in contact komen (hierna ‘u’ genoemd).
            <br/><br/>
            Upgrade Estate NV hecht veel belang aan de bescherming van uw persoonsgegevens en uw persoonlijke levenssfeer en behandelt en beschermt uw persoonsgegevens dan ook op een transparante, behoorlijke en rechtmatige wijze. Onder ‘persoonsgegevens’ verstaan wij ‘elke vorm van informatie die u toelaat om uzelf direct of indirect als een natuurlijke persoon te identificeren’.
            <br/>
            Bij de verwerking van uw persoonsgegevens leeft Upgrade Estate NV evident de wet na, in het verleden de Belgische wet van 8 december 1992 en vanaf 25 mei 2018 de Europese verordening nr. 2016/679 van 27 april 2016 betreffende de gegevensbescherming (‘GDPR’ of ‘AVG’) of enig ander wetgevingsbesluit die deze wijzigt.<br/>
            Meer informatie over de bescherming van persoonsgegevens is te verkrijgen bij de Belgische Commissie voor de bescherming van de persoonlijke levenssfeer, ook wel de Gegevensbeschermingsautoriteit genoemd.
            <br/>
            Neem deze verklaring zorgvuldig door zodat u het beleid van Upgrade Estate NV op vlak van privacy kent en begrijpt. Bovendien wordt deze verklaring regelmatig bijgewerkt. De meest recente versie van de verklaring kunt u vinden op onze website. Wij zullen u op de hoogte brengen via onze gebruikelijke communicatiekanalen wanneer er belangrijke wijzigingen zijn aangebracht.
            </p>

            <h2 className="text-lg font-semibold mt-5 mb-3">II. VERWERKING VAN PERSOONSGEGEVENS</h2>
            <p>
            Upgrade Estate NV is verantwoordelijk voor de verwerking van uw persoonsgegevens:
            <br/><br/>
            Upgrade Estate NV – Burggravenlaan 31, 9000 Gent – BE 0840.066.124 – privacy@upgrade-estate.be – 092337666
            <br/><br/>
            Upgrade Estate NV dient verantwoording af te leggen aan de Gegevensbeschermingsautoriteit aangaande de verwerking van uw persoonsgegevens. Upgrade Estate NV bepaalt de doeleinden waarvoor uw persoonsgegevens worden verwerkt alsook de gebruikte middelen en de verwerkingseigenschappen.<br/>
            Upgrade Estate NV kan beroep doen op gespecialiseerde dienstverleners om uw persoonsgegevens namens Upgrade Estate NV en volgens de instructies van Upgrade Estate NV te verwerken in overeenstemming met deze privacy statement. Enkel de persoonsgegevens die strikt noodzakelijk zijn zullen aan deze dienstverleners worden doorgegeven (hierna de ‘verwerkers van Upgrade Estate NV’ genoemd).
            </p>

            <h2 className="text-lg font-semibold mt-5 mb-3">I. PRIVACY BELEID: inleiding</h2>
            <p>
            Hieronder een overzicht van de categorieën van persoonsgegevens die Upgrade Estate NV kan verwerken. Bovendien is het mogelijk dat ook andere gegevens worden verwerkt indien deze nodig of nuttig zijn voor de voortzetting van de contractuele relatie met Upgrade Estate NV. Ook de context waarin Upgrade Estate NV deze gegevens verkrijgt of verwerkt wordt in onderstaande tabel aangegeven. Deze tabel moet gelezen worden samen met de beschrijving van de doeleinden waarvoor Upgrade Estate NV de gegevens verwerkt.
            <table className="mt-16 mb-16">
              <tr>
                <th>Categorie</th>
                <th>Voorbeelden</th>
                <th>Context</th>
              </tr>
              <tr>
                <td>
                1/ Identiteitsgegevens van de klant of opdrachtgever (verkoper of verhuurder)<br/><br/>
                2/ Identiteitsgegevens van kandidaat koper of huurder
                </td>
                <td>
                Naam, adres, e-mailadres, plaats en datum van geboorte, nationaliteit, vast of mobiel telefoonnummer.	
                </td>
                <td>
                Deze gegevens worden door u verstrekt en verwerkt wanneer u inlichtingen aanvraagt, klant wordt, een bemiddelingsopdracht sluit of u registreert om de diensten van Upgrade Estate NV (online) te gebruiken, voor elke identificatie of gebruik. Deze gegevens kunnen onder meer door het inlezen van uw identiteitskaart worden verkregen of door het invullen van ons klantenportaal ‘Omniboxx’.
                </td>
              </tr>
              <tr>
                <td>
                Gegevens over het onroerend goed van de klant of opdrachtgever (verkoper of verhuurder)	
                </td>
                <td>
                Eigendomstitel, stedenbouwkundig uittreksel, keuringsattest van de elektrische installatie, EPC-attest, postinterventiedossier, informatie over de overstroomgevoeligheid, bodemattest, informatie over de erfgoedwaarde	
                </td>
                <td>
                Deze gegevens worden door u verstrekt, dan wel in uw opdracht door Upgrade Estate NV opgevraagd bij de overheid of technische experten.
                </td>
              </tr>
              <tr>
                <td>
                Gegevens van kandidaat-huurder die verband houden met een voorstel van verhuurcontract	
                </td>
                <td>
                Informatie over beroep en werkgelegenheid, studie, school waar de studie wordt gevolgd, solvabiliteit, gezinssituatie, loon.
                </td>
                <td>
                Deze gegevens worden verzameld met behulp van formulieren en contracten die u op uw verzoek invult.
                </td>
              </tr>
              <tr>
                <td>
                Aanvullende gegevens voor het sluiten van een huurovereenkomst of onderhandse verkoopovereenkomst	
                </td>
                <td>
                Rijksregisternummer
                </td>
                <td>
                Deze gegevens worden door u verstrekt. Het nationaal rijksregisternummer wordt alleen gebruikt in de gevallen waarin de wet voorziet of vereist.
                </td>
              </tr>
              <tr>
                <td>
                Gegevens van mede-eigenaars (of hun huurders)	
                </td>
                <td>
                Naam, adres, plaats en datum van geboorte, nationaliteit, vast of mobiel telefoonnummer en het e-mailadres.	
                </td>
                <td>
                Deze gegevens worden door u als mede-eigenaar verstrekt aan Upgrade Estate NV wanneer Upgrade Estate NV de functie van syndicus uitoefent
                </td>
              </tr>
              <tr>
                <td>
                Gegevens die verband houden met de uitvoering van de dienstverlening van syndicus	
                </td>
                <td>
                Betalingsverrichtingen, inclusief namen en rekeningnummers, communicatie en referenties, kaartnummers.	
                </td>
                <td>
                Deze gegevens hebben betrekking op uw verrichtingen als mede-eigenaar die door de syndicus worden gecoördineerd
                </td>
              </tr>
              <tr>
                <td>
                Gegevens over de verrichtingen van Upgrade Estate NV met u	
                </td>
                <td>
                De informatie in verband met uw contacten met Upgrade Estate NV, per telefoon, e-mail, fax enz. en uw interacties op de webpagina’s aangaande Upgrade Estate NV, haar diensten of haar partners, op sociale netwerken.	
                </td>
                <td>
                Deze gegevens komen van contacten van welke aard ook tussen u en Upgrade Estate NV (bij ontmoetingen of evenementen, seminars, beurzen, abonnement op een nieuwsbrief, sociale mediakanalen, sociale netwerken)
                </td>
              </tr>
              <tr>
                <td>
                Gegevens over het gedrag en voorkeuren van de klant	
                </td>
                <td>
                Informatie over uw gedrag en voorkeuren met betrekking tot het gebruik van communicatie- en relatiekanalen, informatie over uw persoonlijke keuzes en levensstijl, privé of professioneel projecten	
                </td>
                <td>
                Deze gegevens zijn het gevolg van keuzes die u hebt geuit of analyses die door Upgrade Estate NV zijn uitgevoerd in overeenstemming met deze verklaring en haar wettelijke verplichtingen.
                </td>
              </tr>


            </table>

            Upgrade Estate NV verwerkt geen gegevens die uw etnische afkomst of ras onthullen, of politieke opvattingen, levensbeschouwelijke of religieuze overtuigingen, lidmaatschap van een vakbond, gezondheids- of levensgegevens of genetische gegevens, seksuele geaardheid of biometrische gegevens.
            </p>

            <h2 className="text-lg font-semibold mt-5 mb-3">IV. Voor welke doeleinden verwerkt Upgrade Estate NV uw persoonsgegevens?</h2>
            <p>
            Upgrade Estate NV verzamelt en verwerkt uw persoonsgegevens enkel en alleen voor de hieronder beschreven doeleinden. Upgrade Estate NV zorgt ervoor dat enkel noodzakelijke en relevante gegevens voor een bepaald doel worden verwerkt. Upgrade Estate NV verwerkt uw gegevens – in de door de wet toegestane situaties – namelijk:
            <br/><br/>
            - Om te voldoen aan de wettelijke verplichtingen die op Upgrade Estate NV rusten<br/>
            - Om een contract met u uit te voeren of om op uw verzoek vóór de sluiting van dit contract bepaalde maatregelen te nemen<br/>
            - Voor de behartiging van de gerechtvaardigde belangen van Upgrade Estate NV, waarbij deze belangen met uw grondrechten en fundamentele vrijheden wordt afgewogen<br/>
            - In specifieke gevallen, met uw toestemming, als gevolg van een specifiek en ondubbelzinnig verzoek, voorafgegaan door duidelijke informatie. Deze toestemming kan door u op elk moment in overeenstemming met de wet ingetrokken worden.<br/>
            </p>

            <h2 className="text-lg font-semibold mt-5 mb-3">Wettelijke verplichtingen</h2>
            <p>
            Upgrade Estate NV dient zich te houden aan wetgeving die haar in specifieke omstandigheden verplicht om uw persoonsgegevens te verwerken in het kader van haar activiteiten. Deze verplichtingen kunnen met zich meebrengen dat Upgrade Estate NV moet samen te werken met de bevoegde autoriteiten en/of derden, en eventueel bepaalde van uw persoonsgegevens aan hen overmaakt. Hieronder vallen onder meer:            <br/><br/>
            - De verplichting om bij te dragen aan het voorkomen van het witwassen van geld en de financiering van terrorisme;<br/>
            - De verplichting die aan een syndicus worden opgelegd aangaande het beheer van mede-eigendommen;<br/>
            - De verplichting om te reageren op een formeel verzoek van Belgische belasting- en gerechtelijke autoriteiten.<br/>
            <br/>
            De lijst van wettelijke verplichtingen waarvoor Upgrade Estate NV uw gegevens moet verwerken, is niet exhaustief en is onderhevig aan veranderingen.
            </p>

            <h2 className="text-lg font-semibold mt-5 mb-3">Contractuele relaties tussen U als klant en Upgrade Estate NV</h2>
            <p>
            Alvorens contracten te sluiten dient Upgrade Estate NV in sommige situaties bepaalde gegevens verkrijgen en verwerken, met name om onder andere :<br/><br/>
            - te reageren op uw verzoek;<br/>
            - u bij te staan bij het verstrekken van informatie en bij de sluiting van het contract;<br/>
            - een verzoek op te volgen, de geschiktheid te evalueren en de risico’s met betrekking tot een mogelijk contract in te schatten;<br/>
            - uw bemiddelingsopdracht te vervullen.<br/>
            <br/>
            In het kader van lopende contracten of het beheer van contracten dient Upgrade Estate NV een aantal persoonsgegevens te verwerken, in het bijzonder om te voldoen aan administratieve en boekhoudkundige verplichtingen.<br/>
            <br/><br/>
            In dit kader kunnen uw gegevens intern – binnen Upgrade Estate NV – worden verzonden naar de verschillende afdelingen, waaronder aan diegene die niet rechtstreeks verantwoordelijk zijn voor de relatie met u of de uitvoering van een bepaald contract. Meer bepaald verwerkt Upgrade Estate NV uw gegevens bij de uitvoering van contracten als volgt:
            <br/><br/>
            - Beheer van bestelde diensten en/of lopende contracten;<br/>
            - Centraal management en algemeen beeld van de klanten;<br/>
            - Beheer van uw vastgoed;<br/>
            - Beheer van de mede-eigendom waarin u mede-eigenaar bent.<br/>
            <br/><br/>
            Upgrade Estate NV kan uw persoonsgegevens voor bijkomende doeleinden verwerken in het kader van de uitvoering van contracten en de relatie met u.
            </p>

            <h2 className="text-lg font-semibold mt-5 mb-3">Gerechtvaardigde belangen van Upgrade Estate NV</h2>

            <p>Upgrade Estate NV verwerkt uw gegevens ook voor de verwezenlijking van haar gerechtvaardigde belangen. Hierbij streeft Upgrade Estate NV ernaar om een billijke balans te vinden tussen het respect voor uw vrijheden en rechten en de noodzaak om gegevens te verwerken, met inbegrip van de bescherming van privacy. Persoonsgegevens worden onder meer verwerkt voor:</p>
            <ul>
            <li>Het personaliseren van de diensten van Upgrade Estate NV</li>
            <li>De verbetering van de kwaliteit van de aan u verleende dienst door:</li>
            <li>Evaluatie en verbetering van processen, campagnes, simulaties en verkoop door het gebruik van verschillende middelen zoals statistische analyse, tevredenheidsonderzoeken en diverse andere zoekmethodes;</li>
            <li>Verbetering van bestaande – of in ontwikkeling zijnde – producten en diensten van Upgrade Estate NV, op basis van enquêtes bij klanten van Upgrade Estate NV, statistieken, tests en feedback van klanten via sociale netwerken (zoals Facebook, Linkedin, Youtube) gekoppeld aan Upgrade Estate NV.</li>
            <li>Toezicht op de activiteiten van Upgrade Estate NV, met inbegrip van de omvang van de omzet, het aantal afspraken in onze kantoren, het aantal oproepen en bezoeken aan de website van Upgrade Estate NV, de aard van de gestelde vragen van klanten etc.;</li>
            <li>Voorbereiden van studies en statistieken, waarbij de gegevens van de betrokkenen anoniem worden verwerkt;</li>
            <li>Opleiding van personeel door middel van praktijkvoorbeelden ter illustratie, met inbegrip van het opnemen van bepaalde telefoongesprekken;</li>
            <li>Gebruik van cookies om het gebruiksgemak van de bezoekers van haar website te verbeteren. Meer informatie over de werking van cookies en de mogelijkheden om het gebruik van cookies te beperken en ze te verwijderen, vindt u terug in ons cookiesbeleid;</li>
            <li>Bewaren van bewijsmateriaal (in archieven);</li>
            <li>De vaststelling, uitoefening, verdediging en het behoud van de rechten van Upgrade Estate NV of alle personen die zij vertegenwoordigt, bijvoorbeeld voor invorderingsprocedures of geschillen.</li>
            </ul>

            <h2 className="text-lg font-semibold mt-5 mb-3">Specifiek gerechtvaardigd belang van Upgrade Estate NV : direct marketing </h2>
            <p>Upgrade Estate NV voert een klantensegmentatie uit – in het bijzonder naargelang uw behoeften – om bijvoorbeeld geschikte panden en/of diensten aan te bieden die overeenstemmen met uw professionele en persoonlijke situatie. Daartoe kan Upgrade Estate NV onder meer:</p>
            <ul>
            <li>De diensten die u reeds gebruikt en uw socio-demografische gegevens beoordelen (zoals leeftijd, familiale samenstelling, inkomsten)</li>
            <li>Uw gedrag analyseren via verschillende kanalen (bezoeken/meetings, e-mails, bezoek aan de website, berichten via onze website, sociale media), uw voorkeuren afleiden en hierdoor de informatie en de internetpagina’s die u hebt bezocht personaliseren;</li>
            <li>De advertenties op de webpagina’s aanpassen zodat ze voldoen aan de interesse die u heeft aangetoond via uw gedrag op onze website, sociale netwerken, in een kantoor of elders (bv. bij gebeurtenissen waar Upgrade Estate NV aanwezig was);</li>
            <li>Het invullen van formulieren vereenvoudigen door bepaalde velden al in te vullen met reeds bekende gegevens en u te vragen om de nauwkeurigheid van deze gegevens te verifiëren en eventueel bij te werken;</li>
            <li>De sleutelmomenten beoordelen waarvoor bepaalde diensten het beste zouden uitkomen voor u;</li>
            <li>Gepersonaliseerde advertenties naar u versturen via de post, per mail of telefonisch.</li>
            </ul>

            <h2 className="text-lg font-semibold mt-5 mb-3">Uw toestemming voor het verwerken en verzenden van elektronische reclame (elektronische direct marketing)</h2>
            <p>Upgrade Estate NV kan uw persoonlijke elektronische contactgegevens (namelijk uw mobiele telefoonnummer en e-mailadres) verwerken persoonlijke informatie, advertenties of voorstellen toe te sturen via directe marketing of nieuwsbrieven. In dit geval dient Upgrade Estate NV vooraf uw specifieke toestemming te vragen.<br />
            Door deze privacy policy te aanvaarden, geeft u uw toestemming voor het verwerken van uw persoonsgegevens voor elektronische direct marketing doeleinden én het versturen van elektronische reclame.</p>

            <h2 className="text-lg font-semibold mt-5 mb-3">V. Aan wie maakt Upgrade Estate NV uw persoonsgegevens over?</h2>
            <p>Upgrade Estate NV behandelt uw persoonsgegevens met de grootste zorg en deelt uw gegevens enkel om u de beste diensten aan te bieden bij de uitvoering van haar opdracht.</p>
            <p>Upgrade Estate NV kan uw gegevens toezenden aan andere entiteiten binnen haar groepsverband in binnenland en buitenland, met name aan de andere entiteiten van de groep die Upgrade Estate NV bijstaan bij de uitvoering, het beheer en de controle van de activiteiten, met inbegrip van rapportage, ondersteuning en computerbeveiliging of het aanbieden van specifieke diensten en producten.</p>
            <p>In sommige gevallen is Upgrade Estate NV bij wet verplicht om uw gegevens te delen met derden, waaronder:</p>
            <ul>
            <li>Toezichthoudende autoriteiten of overheidsinstellingen wanneer er een wettelijke verplichting bestaat om informatie over te maken;</li>
            <li>Gerechtelijke onderzoeksinstanties op hun uitdrukkelijk verzoek.</li>
            </ul>
            <p>Upgrade Estate NV draagt uw gegevens tevens over aan specifieke dienstverleners om te helpen met:</p>
            <ul>
            <li>Het ontwerpen en onderhouden van computerapparatuur en internetapplicaties;</li>
            <li>Het aanbieden van reclame- en communicatiediensten, met inbegrip van online en sociale netwerkproviders;</li>
            <li>Het voorbereiden van rapporten en statistieken, afdrukken van documenten en ontwerpen van producten of diensten;</li>
            <li>De commercialisering van haar activiteiten, de organisatie van evenementen en het beheer van klantencommunicatie.</li>
            </ul>
            <p>Upgrade Estate NV dient uw gegevens ook over te maken aan online en sociale netwerkproviders, die Upgrade Estate NV gebruikt voor het aanbieden van reclamediensten.</p>
            <p>Eveneens kunnen uw persoonlijke gegevens in het kader van gerechtelijke procedures aan gerechtsdeurwaarders of advocaten worden meegedeeld.</p>
            <p>In de hiervoor genoemde gevallen zorgt Upgrade Estate NV ervoor dat derden enkel en alleen beperkte toegang hebben tot de persoonsgegevens die nodig zijn om de vereiste specifieke taken uit te voeren. Upgrade Estate NV zal ervoor zorgen dat derden zich ertoe verbinden de persoonsgegevens op een veilige en vertrouwelijke wijze te gebruiken en te gebruiken in overeenstemming met de instructies van Upgrade Estate NV en zijn gegevensbeschermingsbeleid.</p>
            <p>Upgrade Estate NV bewaart uw persoonsgegevens binnen de EER. Er worden dan ook geen gegevens overgebracht naar landen buiten de EER.</p>

            <h2 className="text-lg font-semibold mt-5 mb-3">VI. Hoe lang worden uw persoonsgegevens bewaard?</h2>
            <p>Upgrade Estate NV zal uw persoonsgegevens niet langer bewaren dan noodzakelijk is voor de verwezenlijking van de doeleinden waarvoor de gegevens worden verzameld. Deze periode is gelinkt aan de wettelijke en fiscale verplichtingen van Upgrade Estate NV, en ook aan de wettelijke noodzaak om uw gegevens bij te houden buiten de bewaringsperiode ter bewijs of om te reageren op verzoeken om informatie van de bevoegde instantie, bijvoorbeeld:</p>
            <ul>
            <li>10 jaar in het kader van de antiwitwaswetgeving;</li>
            <li>10 jaar in het kader van de contractuele aansprakelijkheid van Upgrade Estate NV;</li>
            <li>7 jaar voor de boekhouding en verantwoordigingstukken van de verenigingen van mede-eigenaars naar aanleiding van het uitoefenen van de taak van syndicus.</li>
            </ul>
            <p>Buiten deze perioden worden uw persoonlijke gegevens verwijderd of geanonimiseerd.</p>

            <h2 className="text-lg font-semibold mt-5 mb-3">VII. Hoe worden uw persoonsgegevens beveiligd?</h2>
            <p>Upgrade Estate NV hanteert strikte normen om de persoonsgegevens onder haar controle te beschermen tegen onrechtmatige en ongeoorloofde verwerking en tegen onopzettelijk verlies, beschadiging of vernietiging. Upgrade Estate NV neemt maatregelen van technische en organisatorische aard zoals encryptie, antivirus, firewalls, toegangscontroles, strenge selectie van medewerkers en leveranciers, om ongepaste toegang, openbaarmaking of verlies van uw persoonsgegevens te voorkomen en te detecteren.</p>
            <p>In het onwaarschijnlijke en ongelukkige geval dat uw persoonsgegevens onder de controle van Upgrade Estate NV gecompromitteerd worden door een inbreuk op de informatiebeveiliging, zal Upgrade Estate NV onmiddellijk handelen om de oorzaak van dergelijke inbreuk te identificeren en actie ondernemen via adequate bemiddelende maatregelen. Indien nodig, zal Upgrade Estate NV u op de hoogte brengen van dit incident in overeenstemming met de toepasselijke wetgeving.</p>

            <h2  className="text-lg font-semibold mt-5 mb-3">VIII. Wat zijn uw rechten bij het verwerken van uw persoonsgegevens?</h2>

            <h2  className="text-lg font-semibold mt-5 mb-3">Recht van inzage, verbetering, beperking, wissing, overdraagbaarheid van gegevens en van bezwaar</h2>
            <p>Voor de hierboven vermelde doeleinden heeft u:</p>
            <ul>
            <li>Recht op inzage van uw persoonsgegevens bij Upgrade Estate NV. Dit betekent dat u Upgrade Estate NV kan vragen of zij uw persoonsgegevens verwerkt, voor welke doeleinden deze gegevens worden verwerkt, welke categorieën van gegevens worden verwerkt, en aan wie zij worden meegedeeld;</li>
            <li>Recht van verbetering als u vaststelt dat uw persoonsgegevens onjuist of onvolledig zijn;</li>
            <li>Recht op beperking indien u bijvoorbeeld de juistheid van uw persoonsgegevens betwist en dit gedurende een periode die Upgrade Estate NV toelaat om dit te controleren;</li>
            <li>Recht op wissing van uw persoonsgegevens. Wanneer uw contract met Upgrade Estate NV een einde genomen heeft, kan u Upgrade Estate NV vragen om uw persoonsgegevens te wissen indien dit niet langer nodig is voor de doeleinden waarvoor deze persoonsgegevens werden verzameld. U heeft eveneens de mogelijkheid om te allen tijde de wissing te vragen van persoonsgegevens die door Upgrade Estate NV op basis van uw toestemming worden verwerkt (tenzij Upgrade Estate NV een andere rechtsgrond heeft voor de verwerking) of op basis van het gerechtvaardigd belang van Upgrade Estate NV (tenzij er prevalerende dwingende gerechtvaardigde gronden zijn voor de verwerking). Upgrade Estate NV kan in ieder geval deze persoonsgegevens bijhouden indien dit voor bewijsdoeleinden in het kader van rechtsvorderingen zou vereist zijn;</li>
            <li>Recht op overdraagbaarheid van gegevens die uzelf aan Upgrade Estate NV heeft verstrekt indien uw persoonsgegevens worden verwerkt op basis van een overeenkomst of op basis van uw toestemming voor het verzenden van elektronische communicatie én deze persoonsgegevens worden verwerkt met behulp van geautomatiseerde processen. Onder dit recht kan u Upgrade Estate NV vragen uw persoonsgegevens door te sturen naar uzelf of rechtstreeks doorsturen naar een andere gegevensverwerkingsverantwoordelijke, voor zover dit technisch mogelijk is voor Upgrade Estate NV.</li>
            <li>U heeft het recht om bezwaar te maken tegen de verwerking van uw gegevens voor de doeleinden die zijn vermeld in artikel IV.3 en IV.4. In het geval van IV.3 kan Upgrade Estate NV evenwel de persoonsgegevens blijven verwerken indien er dwingende gerechtvaardigde gronden voor de verwerking zijn die zwaarder wegen dan uw belangen, rechten en vrijheden of gronden zijn die verband houden met het instellen, uitoefenen of onderbouwen van een rechtsvordering.</li>
            </ul>
            <p>U kunt uw rechten uitoefenen door een geschreven verzoek per post te versturen, dat gedateerd is, ondertekend en met kopie van uw identiteitsbewijs bijgesloten naar Upgrade Estate NV, Burggravenlaan 31, bus 1 – 9000 Gent of per e-mail naar privacy@upgrade-estate.be of door het contactformulier op de website te bezoeken.</p>

            <h2  className="text-lg font-semibold mt-5 mb-3">Recht van verzet tegen het verzenden van elektronische reclame</h2>
            <p>In IV.5 werd uw toestemming gevraagd om uw elektronische contactgegevens te verwerken en te gebruiken om commerciële informatie, reclame of persoonlijke voorstellen naar u te sturen (via direct marketingacties of elektronische nieuwsbrieven). Dit geldt met name voor communicatie naar uw mobiele telefoonnummer en e-mailadres.</p>
            <p>U heeft het recht om bezwaar te maken tegen de verwerking van uw persoonsgegevens voor elektronische direct marketing en u te verzetten tegen de toekomstige ontvangst van dergelijke reclame. U kan ons dit op de volgende wijzen kenbaar maken:</p>
            <ul>
            <li>Door het versturen van een e-mail met een kopie van uw identiteitskaart naar privacy@upgrade-estate.be;</li>
            <li>Door het versturen van een brief per post met een kopie van uw identiteitskaart naar Upgrade Estate NV – Burggravenlaan 31, bus 1 – 9000 Gent;</li>
            <li>Door op de link “uitschrijven” (of “unsubscribe”) te klikken onderaan een e-mail of elektronische nieuwsbrief die door Upgrade Estate NV is verzonden;</li>
            <li>Door een verzoek te richten via de contactpagina op onze website.</li>
            </ul>
            <p>De uitoefening van het recht van verzet kan Upgrade Estate NV evenwel niet tegenhouden om u te contacteren voor enig ander doel, met inbegrip van een wettelijke verplichting of de uitvoering van het contract, in overeenstemming met deze verklaring.</p>

            <h2 className="text-lg font-semibold mt-5 mb-3">Recht om een klacht in te dienen</h2>
            <p>Indien u klachten hebt met betrekking tot de verwerking van uw persoonsgegevens, kan u ons contacteren als volgt:</p>
            <ul>
            <li>Per post : Upgrade Estate NV – Burggravenlaan 31 bus 1, 9000 Gent</li>
            <li>Per e-mail : <a href="mailto:privacy@upgrade-estate.be">privacy@upgrade-estate.be</a></li>
            <li>Telefonisch : 09 233 76 66</li>
            <li>Via het contactformulier op onze website</li>
            </ul>
            <p>Ook kunt u een klacht indienen bij de Commissie voor de bescherming van de persoonlijke levenssfeer, per post te Drukpersstraat 15, 1000 Brussel, per e-mail op <a href="mailto:commission@privacycommission.be">commission@privacycommission.be</a>, of telefonisch op +32 2 274 48 00.</p>

          </div>
        : lang == "fr" ? 
          <div className="font-extralight">
            <h1 className="text-5xl font-semibold pb-5">Privacy</h1>
            <h2 className="text-lg font-semibold mt-5 mb-3">I. POLITIQUE DE RESPECT DE LA VIE PRIVÉE&nbsp;: INTRODUCTION</h2>
            <p>La présente politique de respect de la vie privée porte sur le traitement par Upgrade Estate NV/SA des données à caractère personnel de ses clients, personnes physiques, et de toute autre personne physique en contact avec Upgrade Estate NV/SA (dénommés ci-après «&nbsp;vous&nbsp;»).</p>
            <p>Upgrade Estate NV/SA accorde une grande importance à la protection de vos données personnelles et de votre vie privée et veille donc à traiter et protéger vos données personnelles de manière transparente, correcte et légitime. On entend par «&nbsp;données personnelles&nbsp;», «&nbsp;toute forme d’information qui vous permet de vous identifier vous-même directement ou indirectement en tant que personne physique&nbsp;».</p>
            <p>Lors du traitement de vos données personnelles, Upgrade Estate NV/SA respecte évidemment la législation – précédemment la loi belge du 8 décembre 1992 et, depuis le 25 mai 2018, le règlement européen n°2016/679 du 27 avril 2016 relatif à la protection des données («&nbsp;RGPD&nbsp;») ou tout autre acte législatif le modifiant.</p>
            <p>Vous trouverez plus d’informations sur la protection des données à caractère personnel auprès de la Commission de la protection de la vie privée, également appelée <a href="https://www.privacycommission.be/fr">Autorité de protection des données</a>.</p>
            <p>Lisez attentivement cette déclaration pour connaître et comprendre la politique appliquée par Upgrade Estate NV/SA en matière de respect de la vie privée. Cette déclaration est par ailleurs régulièrement mise à jour. La version la plus récente se trouve sur notre site web. Vous serez averti par le biais de nos canaux de communication habituels en cas de modification majeure.</p>

            <h2 className="text-lg font-semibold mt-5 mb-3">II. TRAITEMENT DES DONNÉES À CARACTÈRE PERSONNEL</h2>
            <p>Upgrade Estate NV/SA est responsable du traitement de vos données personnelles&nbsp;:</p>
            <p>Upgrade Estate NV/SA – Burggravenlaan 31, 9000 Gent/Gand – BE 0840.066.124 – <a href="mailto:privacy@upgrade-estate.be">privacy@upgrade-estate.be</a> – 092337666</p>
            <p>Upgrade Estate NV/SA doit rendre compte du traitement de vos données personnelles auprès de l’Autorité de protection des données. Upgrade Estate NV/SA détermine la finalité du traitement de vos données personnelles ainsi que les moyens utilisés et les caractéristiques du traitement.</p>
            <p>Upgrade Estate NV/SA peut recourir à des prestataires de services spécialisés qui traiteront pour son compte vos données personnelles selon les instructions d’Upgrade Estate NV/SA, conformément à la présente déclaration de respect de la vie privée. Seules les données personnelles strictement nécessaires seront transmises à ces prestataires de services (ci-après «&nbsp;sous-traitants d’Upgrade Estate NV/SA&nbsp;»).</p>

            <h2 className="text-lg font-semibold mt-5 mb-3">III. QUELLES sont les CATÉGORIES DE DONNÉES PERSONNELLES TRAITÉES PAR Upgrade Estate NV/SA&nbsp;?</h2>
            <p>Vous trouverez ci-après un aperçu des catégories de données personnelles susceptibles d’être traitées par Upgrade Estate NV/SA. Il est par ailleurs possible que d’autres données soient traitées si celles-ci s’avèrent utiles ou nécessaires à la poursuite de la relation contractuelle avec Upgrade Estate NV/SA. Le contexte dans lequel Upgrade Estate NV/SA obtiendra ou traitera ces données est décrit dans le tableau ci-après. Ce tableau doit être lu conjointement à la description des finalités de traitement des données par Upgrade Estate NV/SA.</p>
            <p>&nbsp;</p>
            <div class="table-responsive">
            <table class="table table-sm table-bordered">
            <tbody>
            <tr>
            <td><strong>Catégorie</strong></td>
            <td><strong>Exemples</strong></td>
            <td><strong>Contexte</strong></td>
            </tr>
            <tr>
            <td>1/ Données d’identité du client ou du donneur d’ordre (vendeur ou bailleur)<p></p>
            <p>2/ Données d’identité du candidat acheteur ou loueur</p></td>
            <td>Nom, adresse postale, adresse e-mail, date et lieu de naissance, nationalité, numéro de téléphone mobile ou fixe</td>
            <td>Ces données sont fournies par vos soins et traitées lorsque vous demandez des informations, devenez client, demandez une mission d’intermédiation ou vous enregistrez pour pouvoir utiliser les services d’Upgrade Estate NV/SA (en ligne), pour toute identification ou utilisation. Ces données peuvent notamment être obtenues par la lecture de votre carte d’identité ou en complétant notre portail client «&nbsp;Omniboxx&nbsp;»</td>
            </tr>
            <tr>
            <td>Données relatives au bien immobilier du client ou du donneur d’ordre (vendeur ou bailleur)</td>
            <td>Titre de propriété, certificat d’urbanisme, certification de l’installation électrique, attestation CPE, dossier de post-intervention, informations liées aux risques d’inondation, attestation de sol, informations relatives à la valeur patrimoniale</td>
            <td>Ces données sont fournies par vos soins ou demandées en votre nom aux autorités ou experts techniques par Upgrade Estate NV/SA</td>
            </tr>
            <tr>
            <td>Données du candidat loueur en lien avec une proposition de contrat de bail</td>
            <td>Informations relatives à l’activité et à l’emploi, études, école, solvabilité, situation familiale, salaire</td>
            <td>Ces données sont collectées par le biais des formulaires et contrats que vous complétez à votre demande.</td>
            </tr>
            <tr>
            <td>Informations complémentaires pour la signature d’un contrat de bail ou d’un contrat de vente sous seing privé</td>
            <td>Numéro de registre national</td>
            <td>Ces données sont fournies par vos soins<p></p>
            <p>Le numéro de registre national est uniquement utilisé lorsque la loi le prévoit ou l’exige</p></td>
            </tr>
            <tr>
            <td>Données des copropriétaires (ou de leurs locataires)</td>
            <td>Nom, adresse, lieu et date de naissance, nationalité, numéro de téléphone fixe ou mobile et adresse e-mail.</td>
            <td>Ces données sont fournies par vos soins en tant que copropriétaire à Upgrade Estate NV/SA lorsqu’Upgrade Estate NV/SA exerce la fonction de syndic</td>
            </tr>
            <tr>
            <td>Données liées à l’exécution des services du syndic</td>
            <td>Paiements, comprenant les noms, numéros de compte, communications, références et numéros de cartes</td>
            <td>Ces données concernent vos activités en tant que copropriétaire, coordonnées par le syndic</td>
            </tr>
            <tr>
            <td>Données relatives aux opérations entre Upgrade Estate NV/SA et vous</td>
            <td>Les informations relatives à vos contacts avec Upgrade Estate NV/SA, par téléphone, e-mail, fax, etc. et vos interactions sur les pages web concernant Upgrade Estate NV/SA, ses services ou ses partenaires, sur les réseaux sociaux</td>
            <td>Ces données proviennent des contacts de toute nature entre vous et Upgrade Estate NV/SA (lors de rencontres ou d’événements, séminaires, salons, abonnement à une newsletter, réseaux sociaux, médias, etc.)</td>
            </tr>
            <tr>
            <td>Données relatives au comportement et aux préférences du client</td>
            <td>Les informations relatives à votre comportement et à vos préférences concernant l’utilisation de canaux de communication et relationnels, informations relatives à vos choix personnels et à votre mode de vie, à vos projets professionnels et privés</td>
            <td>Ces données résultent des choix que vous avez exprimés ou des analyses menées par Upgrade Estate NV/SA conformément à cette déclaration et à ses obligations légales</td>
            </tr>
            </tbody>
            </table>
            </div>
            <p>Upgrade Estate NV/SA ne traite aucune donnée révélant vos origines ethniques, vos convictions politiques, philosophiques ou religieuses, votre adhésion à un syndicat, des informations liées à votre santé, à votre vie ou à votre patrimoine génétique, à votre orientation sexuelle ou encore des informations biométriques.</p>

            <h2 className="text-lg font-semibold mt-5 mb-3">IV. À QUELLES FINS VOS DONNÉES SONT-ELLES TRAITÉES PAR Upgrade Estate NV/SA&nbsp;?</h2>
            <p>Upgrade Estate NV/SA collecte et traite uniquement vos données personnelles aux fins décrites ci-après. Upgrade Estate NV/SA veille à ce que seules les données utiles et nécessaires à des fins précises soient traitées. Upgrade Estate NV/SA traite vos données – dans les situations permises par la loi – pour&nbsp;:</p>
            <ul>
            <li>Satisfaire aux obligations légales incombant à Upgrade Estate NV/SA</li>
            <li>Conclure un contrat avec vous ou prendre des mesures déterminées à votre demande avant la conclusion de ce contrat</li>
            <li>Défendre les intérêts légitimes d’Upgrade Estate NV/SA, lesquels prennent en compte vos libertés et droits fondamentaux</li>
            <li>Dans des cas spécifiques, avec votre consentement, à la suite d’une demande spécifique et univoque, précédée d’informations claires. Vous avez le droit de révoquer ce consentement à tout moment, conformément à la loi.</li>
            </ul>

            <h2 className="text-lg font-semibold mt-5 mb-3">OBLIGATIONS LÉGALES</h2>
            <p>Upgrade Estate NV/SA est tenue de respecter la législation qui l’oblige, dans des circonstances spécifiques, à traiter vos données personnelles dans le cadre de ses activités. Ces obligations peuvent contraindre Upgrade Estate NV/SA à collaborer avec les autorités compétentes et/ou des tiers, et éventuellement à leur transmettre certaines de vos données personnelles. Elles incluent notamment&nbsp;:</p>
            <ul>
            <li>L’obligation de contribuer à la prévention du blanchiment d’argent et au financement du terrorisme&nbsp;;</li>
            <li>L’obligation qui incombe à un syndic concernant la gestion de copropriétés&nbsp;;</li>
            <li>L’obligation de répondre à une requête formelle provenant des autorités fiscales et judiciaires belges.</li>
            </ul>
            <p>La liste des obligations légales pour lesquelles Upgrade Estate NV/SA doit traiter vos données est non exhaustive et peut faire l’objet de changements.</p>

            <h2 className="text-lg font-semibold mt-5 mb-3">RELATIONS CONTRACTUELLES ENTRE VOUS (EN TANT QUE CLIENT) ET Upgrade Estate NV/SA</h2>
            <p>Avant de conclure un contrat, Upgrade Estate NV/SA doit obtenir et traiter des données dans certaines situations, notamment pour&nbsp;:</p>
            <ul>
            <li>répondre à vos demandes&nbsp;;</li>
            <li>vous aider dans la transmission d’informations et la conclusion du contrat&nbsp;;</li>
            <li>assurer le suivi d’une demande, évaluer la pertinence et les risques liés à un contrat potentiel&nbsp;;</li>
            <li>remplir pour vous une mission d’intermédiation.</li>
            </ul>
            <p>Dans le cadre de contrats en cours ou de la gestion de contrats, Upgrade Estate NV/SA doit traiter un certain nombre de données à caractère personnel, en particulier pour satisfaire à ses obligations administratives et comptables.</p>
            <p>Dans ce cadre, vos données peuvent être transmises en interne – au sein d’Upgrade Estate NV/SA – aux différents départements, notamment à ceux qui ne sont pas directement responsables de la relation que nous entretenons avec vous ou de l’exécution d’un contrat déterminé. Plus particulièrement, Upgrade Estate NV/SA traite vos données lors de l’exécution de contrats comme suit&nbsp;:</p>
            <ul>
            <li>Gestion des services commandés et/ou contrats en cours&nbsp;;</li>
            <li>Gestion centrale et image générale des clients&nbsp;;</li>
            <li>Gestion de vos biens immobiliers&nbsp;;</li>
            <li>Gestion des biens dont vous êtes copropriétaire.</li>
            </ul>
            <p>Upgrade Estate NV/SA peut traiter vos données personnelles à d’autres fins dans le cadre de l’exécution des contrats et de sa relation avec vous.</p>

            <h2 className="text-lg font-semibold mt-5 mb-3">INTÉRÊTS LÉGITIMES D’Upgrade Estate NV/SA</h2>
            <p>Upgrade Estate NV/SA traite également vos données dans le but de défendre ses intérêts légitimes. En ce sens, Upgrade Estate NV/SA s’efforce de trouver un juste équilibre entre le respect de vos droits et libertés et la nécessité de traiter vos données, en tenant compte de la protection de la vie privée. Les données à caractère personnel sont notamment traitées pour&nbsp;:</p>
            <ul>
            <li>Personnaliser les services d’Upgrade Estate NV/SA</li>
            <li>Améliorer la qualité des services qui vous sont offerts en&nbsp;:</li>
            <li>– Évaluant et améliorant les processus, campagnes, simulations et vente par différents moyens tels qu’analyses statistiques, enquêtes de satisfaction et autres méthodes de recherche&nbsp;;</li>
            <li>– Améliorant les produits et services actuels – ou en cours de développement – d’Upgrade Estate NV/SA, sur la base des enquêtes menées auprès des clients d’Upgrade Estate NV/SA, statistiques, tests et feed-back des clients via les réseaux sociaux (tels que Facebook, LinkedIn, Youtube) liés à Upgrade Estate NV/SA.</li>
            <li>Contrôler les activités d’Upgrade Estate NV/SA, en ce compris le volume des ventes, le nombre de rendez-vous dans nos bureaux, le nombre d’appels et de visites de notre site web, la nature des questions posées par les clients, etc.&nbsp;;</li>
            <li>Préparer les études et statistiques, ce qui inclut le traitement anonyme des personnes concernées&nbsp;;</li>
            <li>Former le personnel au moyen d’exemples pratiques à titre indicatif, comprenant l’enregistrement de certains entretiens téléphoniques&nbsp;;</li>
            <li>Utiliser des cookies pour améliorer l’expérience des visiteurs sur son site web. Pour en savoir plus sur le fonctionnement des cookies et les possibilités en matière de limitation et de suppression des cookies, consultez notre politique d’utilisation des cookies&nbsp;;</li>
            <li>Conserver des éléments de preuve (dans les archives)&nbsp;;</li>
            <li>Constater, exercer, défendre et préserver les droits d’Upgrade Estate NV/SA ou de toute personne représentée par Upgrade Estate NV/SA, par exemple pour les procédures de recouvrement ou les litiges.</li>
            </ul>

            <h2 className="text-lg font-semibold mt-5 mb-3">INTÉRÊT LÉGITIME SPÉcifique D’Upgrade Estate NV/SA : marketing DIRECT</h2>
            <p>Upgrade Estate NV/SA applique une segmentation de la clientèle – plus particulièrement en fonction de vos besoins – pour vous proposer, par exemple, des biens et/ou services pertinents qui correspondent à votre situation personnelle et professionnelle. Upgrade Estate NV/SA peut notamment à cet effet&nbsp;:</p>
            <ul>
            <li>Évaluer les services que vous utilisez déjà et vos données sociodémographiques (comme votre âge, la composition de votre ménage et vos revenus)</li>
            <li>Analyser votre comportement à travers différents canaux (visites/réunions, e-mails, visite du site web, messages sur notre site web, réseaux sociaux), déduire vos préférences et ainsi personnaliser les informations et pages internet que vous avez consultées&nbsp;;</li>
            <li>Adapter les publicités sur les pages web de manière à les faire correspondre à l’intérêt que vous avez montré au travers de votre comportement sur notre site web, nos réseaux sociaux, dans un bureau ou ailleurs (p.ex.&nbsp;: lors d’événements auxquels Upgrade Estate NV/SA était présente)&nbsp;;</li>
            <li>Simplifier la manière de remplir des formulaires en préremplissant certains champs grâce aux informations déjà connues et en vous demandant de contrôler et si nécessaire corriger ces données&nbsp;;</li>
            <li>Déterminer les moments clés auxquels des services déterminés pourraient le mieux vous convenir&nbsp;;</li>
            <li>Vous envoyer des publicités personnalisées par courrier, e-mail ou téléphone.</li>
            </ul>

            <h2 className="text-lg font-semibold mt-5 mb-3">VOTRE AUTORISATION POUR LE TRAITEMENT ET L’ENVOI DE PUBLICITÉ ÉLECTRONIQUE (MARKETING NUMÉRIQUE DIRECT)</h2>
            <p>Upgrade Estate NV/SA peut traiter vos données personnelles électroniques (autrement dit votre numéro de GSM et votre adresse e-mail) pour envoyer des propositions, publicités et informations personnelles par le biais de marketing direct ou de newsletters. Dans ce cas, Upgrade Estate NV/SA doit préalablement obtenir votre autorisation spécifique.<br />
            En acceptant la présente politique de respect de la vie privée, vous consentez au traitement de vos données personnelles à des fins de marketing direct et à l’envoi de publicité électronique.</p>

            <h2 className="text-lg font-semibold mt-5 mb-3">V. À QUI Upgrade Estate NV/SA TRANSMET-ELLE VOS DONNÉES PERSONNELLES&nbsp;? </h2>
            <p>Upgrade Estate NV/SA traite vos données personnelles avec le plus grand soin et les partage uniquement en vue de vous offrir un service optimal dans l’exécution de sa mission.</p>
            <p>Upgrade Estate NV/SA peut transmettre vos données à d’autres entités au sein de son groupe en Belgique ou à l’étranger, soit aux autres entités du groupe qui aident Upgrade Estate NV/SA dans l’exécution, la gestion et le contrôle des activités, en ce compris la notification (rapportage), le soutien et la protection informatique ou la fourniture de services et produits spécifiques.</p>
            <p>Upgrade Estate NV/SA peut dans certains cas être contrainte par la loi de partager vos données avec des tiers, notamment&nbsp;:</p>
            <ul>
            <li>Les autorités de surveillance ou organismes publiques en cas d’obligation légale de transmettre des informations&nbsp;;</li>
            <li>Les organes d’enquête judiciaire à leur demande expresse.<br />
            Upgrade Estate NV/SA transmet également vos données à certains prestataires de services afin de contribuer à&nbsp;:</li>
            <li>La conception et l’entretien du matériel informatique et des applications internet&nbsp;;</li>
            <li>La fourniture de services de publicité et de communication, en ce compris les fournisseurs d’accès internet et réseaux sociaux&nbsp;;</li>
            <li>La préparation de rapports et de statistiques, l’impression de documents et de projets de produits ou services&nbsp;;</li>
            <li>La commercialisation de ses activités, l’organisation d’événements et la gestion de la communication avec la clientèle.</li>
            </ul>
            <p>Upgrade Estate NV/SA doit également communiquer vos données à des fournisseurs d’accès internet et réseaux sociaux, utilisés par Upgrade Estate NV/SA pour la fourniture de services de publicité.</p>
            <p>Vos données personnelles peuvent également être communiquées à des huissiers de justice ou avocats dans le cadre de procédures judiciaires.</p>
            <p>Dans les cas précités, Upgrade Estate NV/SA veillera à fournir uniquement aux tiers un accès limité aux données personnelles nécessaires en vue d’effectuer les tâches spécifiques requises. Upgrade Estate NV/SA veillera à ce que les tiers s’engagent à utiliser les données personnelles de manière sûre et confidentielle et conformément aux instructions et à la politique de protection des données d’Upgrade Estate NV/SA.</p>
            <p>Upgrade Estate NV/SA conserve vos données au sein de l’EEE. Il n’y aura donc pas de transfert de données vers des pays extérieurs à l’EEE.</p>

            <h2 className="text-lg font-semibold mt-5 mb-3">VI. COMBIEN DE TEMPS VOS DONNÉES PERSONNELLES SONT-ELLES CONSERVÉS&nbsp;?</h2>
            <p>Upgrade Estate NV/SA ne conservera pas vos données personnelles plus longtemps qu’il ne le faut pour la réalisation des objectifs pour lesquels elles ont été collectées. Cette période est liée aux obligations légales et fiscales d’Upgrade Estate NV/SA, et à l’obligation légale de conserver vos données en dehors de la période de conservation en guise de preuve ou pour répondre aux demandes d’informations des instances compétentes, par exemple&nbsp;:</p>
            <ul>
            <li style="list-style-type: none">
            <ul>
            <li>10 ans dans le cadre de la législation contre le blanchiment de capitaux&nbsp;;</li>
            <li>10 ans dans le cadre de la responsabilité contractuelle d’Upgrade Estate NV/SA&nbsp;;</li>
            <li>7 ans pour la comptabilité et les pièces justificatives des associations de copropriétaires dans le cadre de l’exercice de la fonction du syndic.</li>
            </ul>
            </li>
            </ul>
            <p>En dehors de ces périodes, vos données sont détruites ou rendues anonymes.</p>

            <h2 className="text-lg font-semibold mt-5 mb-3">VII. COMMENT VOS DONNÉES PERSONNELLES SONT-ELLES PROTÉGÉES&nbsp;?</h2>
            <p>Upgrade Estate NV/SA applique des normes rigoureuses afin de protéger les données personnelles sous son contrôle contre tout traitement abusif et injustifié et contre la destruction, détérioration ou perte involontaire. Upgrade Estate NV/SA prend des mesures techniques et organisationnelles telles que cryptage, antivirus, firewalls, contrôle d’accès, sélection rigoureuse des collaborateurs et fournisseurs, afin de prévenir et détecter tout accès abusif, perte ou publication de vos données personnelles.</p>
            <p>Dans le cas improbable et malencontreux où vos données personnelles sous le contrôle d’Upgrade Estate NV/SA seraient compromises par une atteinte à la sécurité des données, Upgrade Estate NV/SA interviendra immédiatement afin d’identifier la source d’une telle atteinte et entreprendra des actions via les mesures d’intermédiation adéquates. Si nécessaire, Upgrade Estate NV/SA vous informera de cet incident conformément à la législation en vigueur.</p>

            <h2 className="text-lg font-semibold mt-5 mb-3">VIII. QUELS SONT VOS DROITS dans le cadre du traitement de vos donnÉes personnelles&nbsp;?</h2>
            <p><b>Droit d’accès, rectification, limitation, effacement, transférabilité des données et contestation</b></p>
            <p>Dans le cadre des finalités décrites ci-avant, vous bénéficiez des droits suivants&nbsp;:</p>
            <ul>
            <li>Droit d’accès à vos données personnelles chez Upgrade Estate NV/SA. Cela signifie que vous pouvez demander à Upgrade Estate NV/SA si elle traite vos données personnelles, à quelles fins, quelles catégories de données sont traitées et avec qui elles sont partagées&nbsp;;</li>
            <li>Droit de rectification si vous constatez que vos données sont erronées ou incomplètes&nbsp;;</li>
            <li>Droit de limitation, par exemple si vous contestez l’exactitude de vos données personnelles, et ce pendant une période permettant à Upgrade Estate NV/SA de les contrôler&nbsp;;</li>
            <li>Droit de suppression de vos données personnelles. Lorsque votre contrat avec Upgrade Estate NV/SA a pris fin, vous pouvez demander à Upgrade Estate NV/SA de supprimer vos données personnelles si elles ne sont plus nécessaires pour les fins auxquelles elles ont été collectées. Vous avez également la possibilité de demander à tout instant la suppression des données personnelles traitées par Upgrade Estate NV/SA sur la base de votre consentement (à moins qu’Upgrade Estate NV/SA ne possède une autre base juridique pour leur traitement) ou sur la base de l’intérêt légitime d’Upgrade Estate NV/SA (sauf motifs légitimes prévalant en faveur du traitement). Upgrade Estate NV/SA peut en tout état de cause conserver ces données personnelles si nécessaire à titre de preuve dans le cadre d’une procédure judiciaire&nbsp;;</li>
            <li>Droit de transférabilité des données que vous avez fournies vous-même à Upgrade Estate NV/SA si vos données personnelles sont traitées sur la base d’un contrat ou sur la base de votre accord pour l’envoi de communications électroniques et que ces données sont traitées à l’aide de processus automatisés. Ce droit vous permet de demander à Upgrade Estate NV/SA de vous envoyer vos données personnelles ou de les transmettre à un autre responsable du traitement des données, pour autant que cela soit techniquement possible pour Upgrade Estate NV/SA.</li>
            <li>Vous avez le droit de vous opposer au traitement de vos données aux fins mentionnées aux articles IV.3 et IV.4. Dans le cadre de l’article IV.3, Upgrade Estate NV/SA peut toutefois poursuivre le traitement des données personnelles s’il existe en ce sens des motifs légitimes impérieux qui prévalent sur vos intérêts, droits et libertés ou des motifs liés à l’ouverture, à l’exercice ou au soutien d’une procédure judiciaire.</li>
            </ul>
            <p>Vous pouvez exercer vos droits en envoyant par voie postale une demande écrite, datée et signée, accompagnée d’une copie de votre pièce d’identité à Upgrade Estate NV/SA, Burggravenlaan 31, bus 1 – 9000 Gent/Gand ou par e-mail à privacy@upgrade-estate.be ou en complétant le formulaire de contact sur le site web.</p>

            <h2 className="text-lg font-semibold mt-5 mb-3">DROIT D’OPPOSITION À L’ENVOI DE PUBLICITÉ ÉLECTRONIQUE</h2>
            <p>Au point IV.5, il vous a été demandé de nous autoriser à traiter vos données personnelles et à les utiliser pour vous envoyer des informations commerciales, de la publicité ou des propositions personnalisées (à travers des actions de marketing direct ou newsletters électroniques). Cela vaut notamment pour la communication vers votre numéro de GSM et votre adresse e-mail.</p>
            <p>Vous avez le droit de vous opposer au traitement de vos données personnelles à des fins de marketing direct et de refuser de recevoir ce type de publicité à l’avenir. Pour ce faire, vous pouvez&nbsp;:</p>
            <p>Envoyer un e-mail contenant une copie de votre carte d’identité à <a href="mailto:privacy@upgrade-estate.be">privacy@upgrade-estate.be</a>&nbsp;;</p>
            <ul>
            <li>Envoyer une lettre accompagnée d’une copie de votre carte d’identité à Upgrade Estate NV/SA – Burggravenlaan 31, bus 1 – 9000 Gent/Gand&nbsp;;</li>
            <li>Cliquer sur le lien «&nbsp;se désinscrire&nbsp;» (ou «&nbsp;unsubscribe&nbsp;») au bas d’un e-mail ou d’une newsletter envoyée par Upgrade Estate NV/SA&nbsp;;</li>
            <li>Introduire une demande via la page de contact de notre site web.</li>
            </ul>
            <p>L’exercice du droit d’opposition n’empêche cependant pas Upgrade Estate NV/SA de vous contacter à toute autre fin, en ce compris une obligation légale ou l’exécution du contrat, conformément à la présente déclaration.</p>

            <h2 className="text-lg font-semibold mt-5 mb-3">DROIT D’INTRODUIRE UNE PLAINTE</h2>
            <p>Si vous souhaitez introduire une plainte concernant le traitement de vos données à caractère personnel, vous pouvez nous contacter&nbsp;:</p>
            <ul>
            <li>Par voie postale&nbsp;: Upgrade Estate NV/SA – Burggravenlaan 31 bus 1, 9000 Gent/Gand</li>
            <li>Par e-mail : <a href="mailto:privacy@upgrade-estate.be">privacy@upgrade-estate.be</a></li>
            <li>Par téléphone : 09 233 76 66</li>
            <li>Au moyen du formulaire de contact sur notre site web</li>
            </ul>
            <p>Vous avez également la possibilité d’introduire une plainte auprès de la Commission de la protection de la vie privée, par voie postale (rue de la presse 35 à 1000 Bruxelles), par e-mail à <a href="mailto:commission@privacycommission.be">commission@privacycommission.be</a>, ou par téléphone au +32 2 274 48 00.</p>
          </div>
        :
        <div className="font-extralight">
          <h1 className="text-5xl font-semibold pb-5">Privacy</h1>
          <h2 className="text-lg font-semibold mt-5 mb-3">I. PRIVACY POLICY: introduction</h2>
          <p>This privacy policy concerns how Upgrade Estate NV deals with the personal data of its customers (individuals) and all other private persons who come into contact with Upgrade Estate NV (hereinafter referred to as “you”).<br />
          Upgrade Estate NV attaches great importance to protecting your personal data and your privacy and therefore processes and protects your personal data in a transparent, proper, and lawful manner. By ‘personal data’ we understand ‘every form of information that allows you to identify yourself directly or indirectly as a private individual’.<br />
          When processing your personal data, Upgrade Estate NV evidently complies with the law, in the past the Belgian Act of 8 December 1992 and, from 25 May 2018, the European Regulation No. 2016/679 of 27 April 2016 on Data Protection (General Data Protection Regulation – GDPR) or any other legislation that changes this.<br />
          More information on the protection of personal data can be obtained from the Belgian Data Protection Authority or <a href="https://www.privacycommission.be/en">Gegevensbeschermingsautoriteit</a>.<br />
          Please read this statement carefully to ensure that you are familiar with and understand Upgrade Estate NV’s privacy policy. Moreover, this statement will be updated on a regular basis. You will find the most recent version on our website. We will notify you via our usual communication channels of any significant changes.</p>

          <h2 className="text-lg font-semibold mt-5 mb-3">II. PROCESSING OF PERSONAL DATA</h2>
          <p>Upgrade Estate NV is responsible for the processing of your personal data:<br />
          Upgrade Estate NV – Burggravenlaan 31, 9000 Ghent – BE 0840.066.124 – <a href="mailto:privacy@upgrade-estate.be">privacy@upgrade-estate.be</a> – 092337666<br />
          Upgrade Estate NV is accountable to the Data Protection Authority for the processing of your personal data. Upgrade Estate NV determines the purposes for which your personal data will be processed and also the means and processing properties used.<br />
          Upgrade Estate NV may use specialised service providers to process your personal data on behalf of Upgrade Estate NV and in accordance with the instructions of Upgrade Estate NV in accordance with this privacy statement. Only the personal data that are strictly necessary will be passed on to these service providers (hereinafter referred to as the ‘processors of Upgrade Estate NV’).</p>

          <h2 className="text-lg font-semibold mt-5 mb-3">III. Which categories of personal data does Upgrade Estate NV process?</h2>
          <p>Below is an overview of the categories of personal data that Upgrade Estate NV may process. Moreover, other data may also be processed if these are necessary or useful for the continuation of the contractual relationship with Upgrade Estate NV. Also, the context in which Upgrade Estate NV obtains or processes these data is stated in the</p>
          <div class="table-responsive">
          <table class="table table-sm table-bordered">
          <tbody>
          <tr>
          <td>Category</td>
          <td>Examples</td>
          <td>Context</td>
          </tr>
          <tr>
          <td>1/ Identity data of the client or principal (seller or lessor) 2/ Identity data of the prospective buyer or lessee</td>
          <td>Name, address, e-mail address, place and date of birth, nationality, landline or mobile telephone number.</td>
          <td>You provide these data, and they are processed if you request information, become a customer, conclude a brokerage agreement or register to use the services of Upgrade Estate NV (online) for every identification or use. Such data may among other things be obtained by reading your identity card or by filling in our client portal ‘Omniboxx’.</td>
          </tr>
          <tr>
          <td>Data regarding immovable property of the client or principal (seller or lessor)</td>
          <td>Title to ownership, town planning extract, inspection certificate for the electrical system, EPC certificate, post-intervention file, information about the susceptibility to flooding, soil certificate, information on the heritage value</td>
          <td>These data will be provided by you or requested by Upgrade Estate NV on your behalf from the government or technical experts.</td>
          </tr>
          <tr>
          <td>Data of a prospective lessee relating to a proposed lease agreement</td>
          <td>Information on occupation and employment, study, the school where studies are being undertaken, solvency, family situation, wages.</td>
          <td>These data are collected with the help of forms and contracts which you fill out at your request.</td>
          </tr>
          <tr>
          <td>Additional data for the conclusion of a lease agreement or private sale agreement</td>
          <td>National register number</td>
          <td>You provide these data yourself. The national register number will only be used in situations provided or required by law.</td>
          </tr>
          <tr>
          <td>Data of co-owners (or their tenants)</td>
          <td>Name, address, place and date of birth, nationality, landline or mobile telephone number and the e-mail address.</td>
          <td>You, as a co-owner, provide these data to Upgrade Estate NV if Upgrade Estate NV acts as administrator.</td>
          </tr>
          <tr>
          <td>Data related to the performance of the administrator’s services</td>
          <td>Payment operations, including names and account numbers, communication and references, card numbers.</td>
          <td>These data relate to your operations as a co-owner which are coordinated by the administrator</td>
          </tr>
          <tr>
          <td>Data regarding operations between Upgrade Estate NV and you</td>
          <td>The information in connection with your contacts with Upgrade Estate NV, by telephone, e-mail, fax, etc., and your interactions on the web pages regarding Upgrade Estate NV, its services or its partners, on social networks.</td>
          <td>These data come from contacts of any kind whatsoever between you and Upgrade Estate NV (at meetings or events, seminars, trade fairs, subscription to a newsletter, social media channels, social networks, etc.)</td>
          </tr>
          <tr>
          <td>Data about the conduct and preferences of the client.</td>
          <td>Information about your conduct and preferences in relation to the use of communication and relations channels, information about your personal choices and lifestyle, private or professional projects.</td>
          <td>These data are the result of choices that you have made or analyses carried out by Upgrade Estate NV in accordance with this statement and its legal obligations.</td>
          </tr>
          </tbody>
          </table>
          </div>
          <p>Upgrade Estate NV does not process any data revealing your ethnic origin or race, political opinions, philosophical or religious beliefs, trade union membership, health or life details or genetic data, sexual orientation or biometric data.</p>

          <h2 className="text-lg font-semibold mt-5 mb-3">IV. For what purposes does Upgrade Estate NV process your personal data?</h2>
          <p>Upgrade Estate NV collects and processes your personal data only for the purposes described below. Upgrade Estate NV ensures that only necessary and relevant data are processed for a specific purpose. Upgrade Estate NV processes your data in situations permitted by law, namely:</p>
          <ul>
          <li>To comply with the statutory obligations incumbent on Upgrade Estate NV</li>
          <li>To execute a contract with you or to take specific measures at your request before the conclusion of this contract</li>
          <li>To protect the legitimate interests of Upgrade Estate NV, whereby these interests are weighed up against your basic rights and fundamental freedoms</li>
          <li>In specific cases, with your permission, as a consequence of a specific and unambiguous request, preceded by clear information. You may withdraw this permission at any time in accordance with the law.</li>
          </ul>

          <h2 className="text-lg font-semibold mt-5 mb-3">Statutory obligations</h2>
          <p>Upgrade Estate NV must comply with legislation that requires it in specific circumstances to process your personal data in the context of its activities. These requirements may mean that Upgrade Estate NV has to cooperate with the competent authorities and/or third parties, and may have to transfer certain personal data to them. These include:</p>
          <ul>
          <li>The obligation to contribute to the prevention of money laundering and terrorist financing;</li>
          <li>The obligation imposed on an administrator regarding the management of co-ownership;</li>
          <li>The obligation to respond to a formal request from Belgian tax and judicial authorities.</li>
          </ul>
          <p>The list of statutory obligations for which Upgrade Estate NV has to process your data is not exhaustive and is subject to change.</p>

          <h2 className="text-lg font-semibold mt-5 mb-3">Contractual relationship between you as the client and Upgrade Estate NV</h2>
          <p>Before concluding contracts, Upgrade Estate NV must, in some situations, obtain and process certain data, in particular to, among other things:</p>
          <ul>
          <li>respond to your request;</li>
          <li>assist you when providing information and when concluding the contract;</li>
          <li>follow up a request, assess the suitability and the risks in relation to a potential contract;</li>
          <li>fulfil your instructions to act as an intermediary.</li>
          </ul>
          <p>In the context of ongoing contracts or the management of contracts, Upgrade Estate NV has to process certain personal data, in particular, to comply with administrative and accounting obligations.</p>
          <p>In this context, your data may be sent internally – within Upgrade Estate NV – to the various departments, including those not directly responsible for the relationship with you or the execution of a specific contract. More specifically, Upgrade Estate NV processes your data in the execution of contracts as follows:</p>
          <ul>
          <li>Management of ordered services and/or ongoing contracts;</li>
          <li>Central management and overall picture of the client;</li>
          <li>Management of your property;</li>
          <li>Management of the co-ownership in which you are co-owner.</li>
          </ul>
          <p>Upgrade Estate NV may process your personal data for additional purposes in the context of the execution of contracts and the relationship with you.</p>

          <h2 className="text-lg font-semibold mt-5 mb-3">Legitimate interests of Upgrade Estate NV</h2>
          <p>Upgrade Estate NV also processes your data to realise its legitimate interests. In this regard, Upgrade Estate NV seeks to achieve a fair balance between respect for your freedoms and rights and the need to process data, including the protection of privacy. Personal data will be processed, among other things, for:</p>
          <ul>
          <li>Personalising the services of Upgrade Estate NV</li>
          <li>Improving the quality of the service provided to you by:</li>
          <li>– Evaluating and improving processes, campaigns, simulations, and sales by the use of various resources such as statistical analysis, satisfaction surveys, and various other research methods;</li>
          <li>– Improvement of existing – or developing – products or services of Upgrade Estate NV on the basis of surveys of customers of Upgrade Estate NV, statistics, tests and feedback of customers via social networks (such as Facebook, LinkedIn, YouTube) linked to Upgrade Estate NV.</li>
          <li>Monitoring the activities of Upgrade Estate NV, including the turnover, the number of appointments at our offices, the number of calls and visits to the website of Upgrade Estate NV, the type of questions asked by customers, etc.;</li>
          <li>Preparing studies and statistics, where the data of the data subjects are processed anonymously;</li>
          <li>Training employees using practical illustrative examples, including recording certain telephone calls;</li>
          <li>Using cookies to improve the user-friendliness of the website. You will find more information about the functioning of cookies and the possibilities to limit the use of cookies and to remove them in our cookies policy;</li>
          <li>Storing evidence (in archives);</li>
          <li>Recording, exercising, defending and maintaining the rights of Upgrade Estate NV or any persons that it represents, for example for collection procedures or disputes.</li>
          </ul>

          <h2 className="text-lg font-semibold mt-5 mb-3">Specific legitimate interest of Upgrade Estate NV: direct marketing </h2>
          <p>Upgrade Estate NV carries out customer segmentation – in particular, according to your needs – in order, for example, to offer suitable premises and services that correspond to your professional and personal situation. For this purpose, Upgrade Estate NV may, among other things:</p>
          <ul>
          <li>Assess the services that you already use and your socio-demographic data (e.g. age, family composition, income);</li>
          <li>Analyse your behaviour via various channels (visits/meetings, e-mails, website visits, messages via our website, social media), deduce your preferences and use this to personalise the information and the internet pages that you have visited;</li>
          <li>Adapt the advertisements on the web pages so that they meet the interest that you have shown via your behaviour on our website, social networks, in an office, or elsewhere (e.g. at events where Upgrade Estate NV was present);</li>
          <li>Simplify the completion of forms by pre-completing certain fields with already known data and ask you to verify the accuracy of these data and if need be to update these;</li>
          <li>Assess the key moments at which certain services would suit you best;</li>
          <li>Send personalised advertisements to you by post, e-mail, or by telephone.</li>
          </ul>

          <h2 className="text-lg font-semibold mt-5 mb-3">Your consent for the processing and sending of electronic advertising (electronic direct marketing)</h2>
          <p>Upgrade Estate NV may process your personal electronic contact details (i.e. your mobile telephone number and e-mail address) to send you personal information, advertisements or proposals via direct marketing or newsletters. In this case, Upgrade Estate NV has to request your specific prior permission.<br />
          By accepting this privacy policy, you are permitting the processing of your personal data for electronic direct marketing purposes and the sending of electronic advertising.</p>

          <h2 className="text-lg font-semibold mt-5 mb-3">V. To whom WILL Upgrade Estate NV disclose your personal data?</h2>
          <p>Upgrade Estate NV handles your personal data with great care and will only share your data to offer you the best services when carrying out its task.</p>
          <p>Upgrade Estate NV may send your data to other entities within its group in Belgium and abroad, and in particular to other entities in the group that assist Upgrade Estate NV with the implementation, management, and monitoring of the activities, including reporting, support, and computer security, or the offering specific services and products.</p>
          <p>In some cases, Upgrade Estate NV is required by law to share your data with third parties, including:</p>
          <ul>
          <li>Supervisory authorities or government agencies if there is a legal obligation to disclose information;</li>
          <li>Judicial investigation agencies at their express request.</li>
          </ul>
          <p>Upgrade Estate NV will also transfer your data to specific service providers to help with:</p>
          <ul>
          <li>Designing and maintaining computer equipment and internet applications;</li>
          <li>Offering advertising and communication services, including online and social network providers;</li>
          <li>Preparing reports and statistics, printing documents and designing products or services;</li>
          <li>Commercialising its activities, the organisation of events and the management of customer communications.</li>
          </ul>
          <p>Upgrade Estate NV also has to pass your data on to online and social network providers that use Upgrade Estate NV to offer advertising services.</p>
          <p>Your personal data may also be disclosed to bailiffs or lawyers in the context of court proceedings.<br />
          In the above-mentioned cases, Upgrade Estate NV ensures that third parties only have limited access to the personal data that are needed to carry out the required specific tasks. Upgrade Estate NV will ensure that third parties undertake to use the personal data securely and confidentially in accordance with the instructions of Upgrade Estate NV and its data protection policy.</p>
          <p>Upgrade Estate NV stores your personal data within the EEA. No data will be transferred to countries outside the EEA.</p>

          <h2 className="text-lg font-semibold mt-5 mb-3">VI. How long WILL your personal Data BE retained?</h2>
          <p>Upgrade Estate NV will not retain your personal data for longer than is necessary for the achievement of the purposes for which the data are collected. This period is linked to Upgrade Estate NV’s statutory and tax obligations and also the statutory requirement to retain your data outside the retention period as evidence or to respond to requests for information from the competent authority, e.g.:</p>
          <ul>
          <li>Ten years in the context of the anti-money laundering legislation;</li>
          <li>Ten years in the context of the contractual liability of Upgrade Estate NV;</li>
          <li>Seven years for the accounts and accountability documents of associations of co-owners in connection with the performance of an administrator’s duties.<br />
          Your personal data will be erased or anonymised outside these periods.</li>
          </ul>

          <h2 className="text-lg font-semibold mt-5 mb-3">VII. How are your personal data secured?</h2>
          <p>Upgrade Estate NV applies strict standards to protect the personal data under its control against unlawful and unauthorised processing and accidental loss, damage or destruction. Upgrade Estate NV takes measures of a technical and organisational nature such as encryption, anti-virus, firewalls, access controls and strict selection of employees and suppliers to prevent and detect inappropriate access, disclosure or loss of your personal data.</p>
          <p>In the unlikely and unfortunate event that your personal data under the control of Upgrade Estate NV are compromised by a breach of information security, Upgrade Estate NV will immediately act to identify the cause of such breach infringement and take action through appropriate mediating measures. If necessary, Upgrade Estate NV will inform you if this incident in accordance with applicable law.</p>

          <h2 className="text-lg font-semibold mt-5 mb-3">VIII. What are your rights WITH regard TO THE processing OF your personal data?</h2>
          <p>Right of INSPECTION, correction, restriction, erasure, data portability and of objection<br />
          For the above-mentioned purposes you have the:</p>
          <ul>
          <li>Right of inspection of your personal data at Upgrade Estate NV. This means that you can ask Upgrade Estate NV whether it processes your personal data, for what purposes your data are processed, what categories of data are processed, and to whom they are disclosed;</li>
          <li>Right of correction if you determine that your personal data are inaccurate or incomplete;</li>
          <li>Right of restriction if for example you dispute the accuracy of your personal data and do so during a period that allows Upgrade Estate NV to check this;</li>
          <li>Right to erasure of your personal data. Once your contract with Upgrade Estate NV has come to an end, you can ask Upgrade Estate NV to erase your personal data if these are no longer needed for the purposes for which they were collected. You also have the possibility at any time to request erasure of your personal data which Upgrade Estate NV processes on the basis of your consent (unless Upgrade Estate NV has other legal grounds for the processing) or on the basis of the legitimate interest of Upgrade Estate NV (unless there are overriding legitimate grounds for the processing). Upgrade Estate NV may, in any event, retain such personal data if this is required for evidence purposes in the context of legal proceedings;</li>
          <li>Right to data portability as regards data that you have provided to Upgrade Estate NV if your personal data are processed on the basis of an agreement or on the basis of your consent to send electronic communications and these personal data are processed using automated processes. This right allows you to ask Upgrade Estate NV to send your personal data to you or directly to another data controller, insofar as this is technically possible for Upgrade Estate NV.</li>
          <li>You have the right to object to the processing of your data for the purposes stated in Article IV.3 and IV.4. However, in the case of Article IV.3, Upgrade Estate NV may continue to process the personal data if there are compelling legitimate grounds for the processing which outweigh your interests, rights and freedoms or grounds relating to the establishment, exercise or defence of legal claims.</li>
          </ul>
          <p>You can exercise your rights by sending a signed and dated written request by post, including a copy of your proof of identity, to Upgrade Estate NV, Burggravenlaan 31, bus 1 – 9000 Ghent or by e-mail to privacy@upgrade-estate.be or by using the contact form on the website.</p>
          <p>Right to object to THE sending of electronic advertising</p>
          <p>In IV.5, your permission was requested to process and use your electronic contact details to send you commercial information, advertising or personal proposals (via direct marketing campaigns or electronic newsletters). This applies in particular to communications to your mobile telephone number and your e-mail address.</p>
          <p>You have the right to object to the processing of your personal data for electronic direct marketing and to object to receiving such advertising in the future. You can inform us of this in the following ways:</p>
          <ul>
          <li>By sending an e-mail with a copy of your identity card to <a href="mailto:privacy@upgrade-estate.be">privacy@upgrade-estate.be</a>;</li>
          <li>By sending a letter by post with a copy of your identity card to Upgrade Estate NV – Burggravenlaan 31, bus 1 – 9000 Ghent;</li>
          <li>By clicking on the “unsubscribe” link at the bottom of an e-mail or electronic newsletter sent by Upgrade Estate NV;</li>
          <li>By sending a request via the contact page on our website.</li>
          </ul>
          <p>However, the exercise of the right of objection may not prevent Upgrade Estate NV from contacting you for any other purpose, including a legal obligation or the performance of the contract, in accordance with this statement.</p>
          <p>Right to lodge a complaint</p>
          <p>If you have any complaints regarding the processing of your personal data, you can contact us as follows:</p>
          <ul>
          <li>By post: Upgrade Estate NV – Burggravenlaan 31 bus 1, 9000 Ghent</li>
          <li>By e-mail: <a href="mailto:privacy@upgrade-estate.be">privacy@upgrade-estate.be</a></li>
          <li>By telephone: 09 233 76 66</li>
          <li>Via the contact form on our website</li>
          </ul>
          <p>You can also submit a complaint to the Data Protection Authority, by post to Drukpersstraat 35, 1000 Brussels, by e-mail to <a href="mailto:commission@privacycommission.be">commission@privacycommission.be</a>, or by telephone to +32 2 274 48 00.</p>
          </div>
        }
      </div>          
      <Footer />
    </div>
  );
}
