import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

export default function Footer() {
  const { t, i18n } = useTranslation();

  const lngs = {
    en: { nativeName: 'English' },
    nl: { nativeName: 'Nederlands' },
    fr: { nativeName: 'Francais' }
  };
  

  return (
    <footer className="bg-gray-800" aria-labelledby="footer-heading">
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <div className="max-w-6xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        <p className="text-gray-300 text-base">
          {t('footer.disclaimer')}
        </p>

        <div className="xl:grid xl:grid-cols-2 xl:gap-8 pt-10">
          <div className="mt-12 grid grid-cols-2 gap-8 xl:mt-0 xl:col-span-2">
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h3 className="text-md text-gray-400">{t('footer.menu.language.title')}</h3>
                <ul role="list" className="mt-4 space-y-2">
                {Object.keys(lngs).map((lng) => (
                  <li key={lng}>
                    <p className="text-base text-gray-200 hover:text-white" style={{ fontWeight: i18n.resolvedLanguage === lng ? 'bold' : 'normal' }} onClick={() => i18n.changeLanguage(lng)}>
                    {lngs[lng].nativeName}
                    </p>
                  </li>
                ))}
                </ul>
              </div>
              <div className="mt-12 md:mt-0">
                <h3 className="text-md text-gray-400">{t('footer.menu.joinus.title')}</h3>
                <ul role="list" className="mt-4 space-y-2">
                  <li>
                    <a
                      href={
                        process.env.REACT_APP_BACKEND_URL + i18n.resolvedLanguage + "/node/add/listing"
                      }
                      className="text-base text-gray-200 hover:text-white"
                    >
                      {t('footer.menu.joinus.add')}
                    </a>
                  </li>
                  <li>
                    <a
                      href={process.env.REACT_APP_BACKEND_URL + "user/register"}
                      className="text-base text-gray-200 hover:text-white"
                    >
                      {t('footer.menu.joinus.register')}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h3 className="text-md text-gray-400">{t('footer.menu.help.title')}</h3>
                <ul role="list" className="mt-4 space-y-2">
                  <li>
                    <Link
                      to={"/tips/"}
                      className="text-base text-gray-200 hover:text-white"
                    >
                      {t('footer.menu.help.tips')}
                    </Link>
                  </li>
                  {/* <li>
                    <Link
                      to={"/tips/"}
                      className="text-base text-gray-200 hover:text-white"
                    >
                      {t('footer.menu.help.FAQ')}
                    </Link>
                  </li> */}
                </ul>
              </div>
              <div className="mt-12 md:mt-0">
                <h3 className="text-md text-gray-400">{t('footer.menu.UE.title')}</h3>
                <ul role="list" className="mt-4 space-y-2">
                  <li>
                    <Link
                      to={"/contact/"}
                      className="text-base text-gray-200 hover:text-white"
                    >
                      {t('footer.menu.UE.contact')}
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={"/disclaimer/"}
                      className="text-base text-gray-200 hover:text-white"
                    >
                      {t('footer.menu.UE.disclaimer')}
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-12 pt-8">
          <p className="text-sm text-gray-600">
            <Link to={"/terms/"} className="pr-10">
              {t('footer.terms')}
            </Link>
            <Link to={"/privacy/"} className="pr-10">
              {t('footer.privacy')}
            </Link>
            <a href="#">Upgrade Estate, {new Date().getFullYear()}</a>
          </p>
        </div>
      </div>
    </footer>
  );
}
