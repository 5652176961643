// import packages
import { useEffect } from 'react';
import { Routes, Route, useLocation } from "react-router-dom";
import { useCookies } from 'react-cookie';
import ReactGA from 'react-ga4';

// import css
import "./App.css";

//import views
import HomeView from "./views/home.view";
import Listings from "./views/listings.view";
import Listing from "./views/listing.view";
import NotFound from "./views/404.view"
import ErrorPage from "./views/500.view"

//import static views
import Tips from "./views/static/tips.view";
import Terms from "./views/static/terms.view";
import Contact from "./views/static/contact.view";
import Disclaimer from "./views/static/disclaimer.view";
import Privacy from "./views/static/privacy.view";

//import components
import NotificationBar from "./components/NotificationBar.component"

//import datastores (?)

//import services (?)

function App() {
  ReactGA.initialize("G-WKLMEBLRRB");
  const [alertCookie, setAlertCookie] = useCookies(['alert']);

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
  }, [useLocation()]);

  return (
    <>
        <Routes>
          <Route path="/" element={<HomeView />} />

          {/* listing routes */}
          <Route path=":path/" element={<Listings />} />
          <Route path=":path/:city/" element={<Listings />} />
          <Route path=":path/:city/:street/" element={<Listings />} />
          <Route path=":path/:city/:street/:id/" element={<Listing />} />
          <Route path="/redirect/:id/" element={<Listing />} />

          {/* static routes */}
          <Route path="/tips/" element={<Tips />} />
          <Route path="/contact/" element={<Contact />} />
          <Route path="/disclaimer/" element={<Disclaimer />} />
          <Route path="/terms/" element={<Terms />} />
          <Route path="/privacy/" element={<Privacy />} />

          {/* 500 route */}
          <Route path="/sorry/" element={<ErrorPage />} />

          {/* 404 route */}
          <Route path="*" element={<NotFound />} />

        </Routes>
      { !alertCookie || alertCookie.check != "true" 
      ? <NotificationBar />
      : ""
      }
    </>
  );
}

export default App;
