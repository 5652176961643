import { useTranslation } from "react-i18next";
import { useEffect, useState, useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import listingsService from "../services/listings.service";

export default function ContactCard({
  nid,
  price,
  extra,
  LocationREF,
  location,
}) {
  const { t } = useTranslation();

  const [stopScroll, setStopScroll] = useState(false);
  const [formstate, setFormstate] = useState(0);
  const [inputMessage, setInputMessage] = useState("");
  const [inputName, setInputName] = useState("");
  const [inputEmail, setInputEmail] = useState("");
  const [inputPhone, setInputPhone] = useState("");
  const [resultMessage, setResultMessage] = useState();

  const ContactCardREF = useRef(null);

  useEffect(() => {
    setInputMessage(t("listing.contact.form.first") + " " + location + ".");
  }, []);

  useEffect(() => {
    if (
      (parseInt(ContactCardREF.current.offsetHeight) +
        parseInt(ContactCardREF.current.offsetTop)) *
        1.5 >=
      LocationREF.current.offsetTop
    ) {
      setStopScroll(true);
    }
  });

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  function submit(value) {
    if (typeof value != "string") {
      setFormstate(formstate + 1);
    } else {
      listingsService
        .submitContact(
          nid,
          inputMessage,
          inputName,
          inputEmail,
          inputPhone,
          value
        )
        .then((response) => {
          setResultMessage({ status: "ok", message: response.data[0] });
        })
        .catch((error) => {
          setResultMessage({ status: "fail", message: error });
        });
    }
  }

  return (
    <>
      <div
        className={classNames(
          stopScroll ? "lg:absolute" : "fixed",
          "shadow-lg rounded-lg lg:mx-16 lg:z-50 lg:w-1/4 lg:-mt-24 w-full mb-20"
        )}
        ref={ContactCardREF}
      >
        <div className="bg-indigo-100 text-center py-5 rounded-t-lg">
          <h5 className="font-semibold text-indigo-700">
            {t("listing.contact.title")}
          </h5>
        </div>
        <div className="p-5 border-b bg-white">
          <h5 className="font-semibold text-lg">
            {t("listing.contact.overview")}
          </h5>
          <div className="flex py-5">
            <div className="w-1/2">
              <p className="font-light">{t("listing.contact.price")}</p>
            </div>
            <div className="w-1/2 text-right">
              <p className="font-semibold">€{price ? price : "?"}</p>
            </div>
          </div>
          <div className="flex">
            <div className="w-1/2">
              <p className="font-light">{t("listing.contact.extra")}</p>
            </div>
            <div className="w-1/2 text-right">
              <p className="font-semibold">
                {extra
                  ? extra == "0"
                    ? "€" + extra
                    : t("listing.contact.not_included")
                  : t("listing.contact.not_included")}
              </p>
            </div>
          </div>
        </div>
        <div className="p-5 bg-white rounded-b-lg text-sm">
          <h5 className="font-semibold text-lg">
            {t("listing.contact.contact")}
          </h5>
          {formstate >= 1 ? (
            <>
              <div className="greychat">
                <p>{inputMessage ? inputMessage : ""}</p>
              </div>
              <div className="bluechat">
                <p>{t("listing.contact.form.namequestion")}</p>
              </div>
            </>
          ) : (
            <></>
          )}
          {formstate >= 2 ? (
            <>
              <div className="greychat">
                <p>{inputName ? inputName : ""}</p>
              </div>
              <div className="bluechat">
                <p>{t("listing.contact.form.emailquestion")}</p>
              </div>
            </>
          ) : (
            <></>
          )}
          {formstate >= 3 ? (
            <>
              <div className="greychat">
                <p>{inputEmail ? inputEmail : ""}</p>
              </div>
              <div className="bluechat">
                <p>{t("listing.contact.form.phonequestion")}</p>
              </div>
            </>
          ) : (
            <></>
          )}
          {formstate >= 4 ? (
            <>
              <div className="greychat">
                <p>{inputPhone ? inputPhone : ""}</p>
              </div>
              <div className="bluechat">
                <p>{t("listing.contact.form.robot")}</p>
              </div>
            </>
          ) : (
            <></>
          )}

          {formstate >= 4 && resultMessage ? (
            <>
              <div className="greychat">
                <p>{t("listing.contact.form.robotproved")}</p>
              </div>
              <div
                className={
                  resultMessage.status == "ok" ? "greenchat" : "redchat"
                }
              >
                <p>
                  {resultMessage.status == "ok" ? (
                    <>
                      {t("listing.contact.form.contactinfo")} <br />
                      📞 {resultMessage.message.phone}
                      <br />
                      📧 {resultMessage.message.email}
                      <br />
                    </>
                  ) : (
                    <>{t("listing.contact.form.error")}</>
                  )}
                </p>
              </div>
            </>
          ) : (
            <></>
          )}

          {formstate == 0 ? (
            <>
              <textarea
                className="bg-gray-100 border-none shadow-sm rounded-lg w-full mt-2 font-thin text-sm"
                rows="3"
                value={inputMessage}
                type="text"
                name="contact"
                onChange={(message) => setInputMessage(message.target.value)}
              />
            </>
          ) : (
            <></>
          )}

          {formstate == 1 ? (
            <>
              <textarea
                className="bg-gray-100 border-none shadow-sm rounded-lg w-full mt-2 font-thin text-sm"
                rows="1"
                value={inputName}
                placeholder={t("listing.contact.form.name")}
                type="text"
                name="name"
                onChange={(name) => setInputName(name.target.value)}
              />
            </>
          ) : (
            <></>
          )}

          {formstate == 2 ? (
            <>
              <textarea
                className="bg-gray-100 border-none shadow-sm rounded-lg w-full mt-2 font-thin text-sm"
                rows="1"
                value={inputEmail}
                placeholder={t("listing.contact.form.email")}
                type="text"
                name="email"
                onChange={(email) => setInputEmail(email.target.value)}
              />
            </>
          ) : (
            <></>
          )}

          {formstate == 3 ? (
            <>
              <textarea
                className="bg-gray-100 border-none shadow-sm rounded-lg w-full mt-2 font-thin text-sm"
                rows="1"
                value={inputPhone}
                placeholder={t("listing.contact.form.phone")}
                type="text"
                name="phone"
                onChange={(phone) => setInputPhone(phone.target.value)}
              />
            </>
          ) : (
            <></>
          )}

          {formstate >= 4 && !resultMessage ? (
            <>
              <ReCAPTCHA
                sitekey="6Lc7dXAgAAAAAEUiamckE2ePk0A_OfnKwaWqAeDy"
                onChange={submit}
              />
            </>
          ) : (
            <></>
          )}

          {formstate != 4 ? (
            <div className="mt-5">
              <span
                onClick={submit}
                className="text-white bg-indigo-600 p-2 rounded-md font-bold pl-3 pr-3"
              >
                {t("listing.contact.form.send")}
              </span>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
}
