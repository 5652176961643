import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import emptyimg from "../assets/img/empty.jpeg"

export default function ImageCard({ title, subtext, image }) {
  const { t } = useTranslation();

  return (
    <div className="flex-col md:w-1/2 lg:w-1/3 w-full p-3">
      <Link to={"/" + title.toLowerCase()}>
        <img src={image ? image : emptyimg} className="w-full" />
        <div className="text-center w-full text-white -mt-16">
          <h1 className="md:text-2xl text-lg font-medium">{title}</h1>
          <p className="md:font-medium font-small">
            {subtext} {subtext == 1 ? t('home.room') : t('home.rooms')}
          </p>
        </div>
      </Link>
    </div>
  );
}
