import { Link } from "react-router-dom";
import Footer from "../components/Footer.component";
import SecondaryMenu from "../components/SecondaryMenu.component";

export default function NotFound() {
  return (
    <div className="bg-grey-100">
      <SecondaryMenu />
      <div className="max-w-6xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 mb-20">
        <h1 className="text-4xl font-semibold pb-10">Page not found!</h1>
        <Link
          to={"/"}
          className="text-white bg-indigo-600 p-2 rounded-md font-bold pl-3 pr-3"
        >
          Back to homepage
        </Link>
      </div>
      <Footer />
    </div>
  );
}
