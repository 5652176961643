import axios from "axios";

const instance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

instance.interceptors.request
  .use
  //empty interceptor: no auth required.
  ();

instance.interceptors.response
  .use
  //empty interceptor: no auth required.
  ();

export default instance;
