//import packages
import { useTranslation, Trans } from 'react-i18next';
import { useEffect, useState } from 'react';


//import components
import SecondaryMenu from "../../components/SecondaryMenu.component";
import Footer from "../../components/Footer.component";
import UELOGO from '../../assets/img/UPGRADE-logo.png'

export default function Disclaimer() {
  const { t, i18n } = useTranslation();
  const [lang, setLang] = useState();

  useEffect(() => {
    document.title = "Disclaimer"
    setLang(i18n.language);
  }, [i18n.language]);

  return (
    <div className="bg-grey-100">
      <SecondaryMenu />
      <div className="max-w-6xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 mb-20">
        {lang == "nl" ?
          <div className="font-extralight">
            <h1 className="text-5xl font-semibold pb-5">Disclaimer</h1>
            <p className="mb-10">
              De websites<br/>
              <b>student-rooms.com</b><br/>
              <b>opkotin.be</b><br/>
              <b>opkotinantwerpen.be</b><br/>
              <b>opkotinbrussel.be</b><br/>
              <b>opkotingent.be</b><br/>
              <b>opkotinhasselt.be</b><br/>
              <b>opkotinkortrijk.be</b><br/>
              <b>opkotinleuven.be</b><br/>
              <b>inbruggeopkot.be</b><br/>
              <br/>
              worden beheerd door:
              <img src={UELOGO} />
              <a href="https://upgrade-estate.be/nl/" target="_blank" className="text-indigo-600 font-normal">UPGRADE ESTATE NV</a>, Burggravenlaan 31, 9000 Gent (België)
            </p>
            <div className="text-sm">
              Het gebruik van één of meerdere van deze sites houdt in dat je de volgende artikels integraal aanvaardt. Voor alle commerciële aangelegenheden gelden, naast deze artikels, de algemene voorwaarden van Upgrade Estate NV.
              <br/><br/>
              De medewerkers van ‘Op Kot in’ + ‘student-rooms’ doen hun best om de juistheid van de informatie op deze website zo goed mogelijk te controleren. Alle gegevens en informatie zijn echter geheel vrijblijvend. Noch Upgrade Estate NV noch de medewerkers van deze website kunnen verantwoordelijk gesteld worden voor eventuele onjuistheden. We raden je dan ook aan de juistheid van de aangeboden informatie na te kijken voor je een overeenkomst afsluit met een verhuurder.
              <br/><br/>
              Verder wil ‘Op Kot in’ + ‘student-rooms’ duidelijk stellen dat er geen verband is met enige Huisvestingsdienst. De informatie op deze website valt dan ook niet binnen de kwaliteits- en selectiecriteria van de Huisvestingsdienst.
              <br/><br/>
              Alle intellectuele rechten met betrekking tot de vormgeving van deze website, de logo’s en de opbouw van de website en de databanken behoren toe aan Upgrade Estate NV.
              <br/><br/>
              De inhoud van deze website wordt beschermd door de wetgeving betreffende auteursrechten. Alle rechten voorbehouden.
              Niets uit deze website mag worden verveelvoudigd en/of openbaar gemaakt via internet, door middel van druk, fotokopie, microfilm of op welke andere wijze dan ook zonder voorafgaande schriftelijke toestemming van Upgrade Estate.
              <br/><br/>
              Elke vorm van deeplinking, in het bijzonder inline linking en/of framed linking, dat strijdig is met het merken– of databankenrecht, het exclusieve recht op reproductie en de wet op de handelspraktijken en waarvoor geen schriftelijke toestemming is verschaft door Upgrade Estate kan met alle juridische middelen tot strafsancties leiden conform het gemeen en bijzonder strafrecht.
              <br/><br/>
              De personen die foto’s en / of afbeeldingen toevoegen aan hun aanbiedingen verbinden zich ertoe uitsluitend afbeeldingen en foto’s te gebruiken die niet in strijd zijn met auteurswetten en goede zeden.
              <br/><br/>
              Hoewel Upgrade Estate NV zijn best doet om de webste 24 op 24 en 7 dagen per week bereikbaar te houden, behouden we ons het recht de toegang tot de website te beperken of af te sluiten zonder voorafgaande mededeling. Upgrade Estate NV of de medewerkers van de website kunnen niet verantwoordelijk gehouden worden voor gevolgen van een mogelijke onderbreking van de toegang.
              <br/><br/>
              We behouden ons ook het recht specifiek bepaalde mensen de toegang tot de website of delen ervan te weigeren zonder voorafgaande mededeling.
              <br/><br/>
              Upgrade Estate NV neemt geen enkele verantwoordelijkheid op voor de informatie op websites of pagina’s waarnaar een link wordt gelegd vanop deze website. De verantwoordelijkheid voor de juistheid en wettelijkheid van de inhoud van die andere websites of pagina’s ligt volledig bij de beheerders van die websites of pagina’s.
              <br/><br/>
              Upgrade Estate NV verzamelt geen persoonsgegevens tenzij deze op vrijwillige basis worden verstrekt. Met het registreren als kotbaas, inschrijven voor de nieuwsbrief, of het verzenden van een e-mail geeft een gebruiker of bezoeker Upgrade Estate NV de toestemming zijn of haar persoonlijke gegevens op te slaan in een bestand van Upgrade Estate NV, met het oog op een geautomatiseerde gebruikersprofilering, klantenbeheer, marktonderzoek en direct mail per post. De verstrekte gegevens kunnen worden gebruikt voor promotionele doeleinden, ook door derden. Je hebt recht op inzage en eventuele correctie van uw desbetreffende persoonsgegevens. Je hebt het recht om je kosteloos te verzetten tegen verwerking van uw gegevens voor direct marketing doeleinden. Je kan je aanvraag tot inzage, correctie of verzet bekomen via ons contactformulier. Bij wijziging of verwijderen van je informatie zijn we echter niet verantwoordelijk voor de mogelijke gevolgen wanneer de diensten op de website voor jou niet meer naar behoren functioneren.
              <br/><br/>
              Bij eventuele geschillen zal er eerst tussen de verschillende partijen onderling tot een overeenkomst gezocht worden. Indien er rechtspraak van toepassing is, geldt alleen de Belgische rechtspraak en de rechtbank van Gent.
            </div>
          </div>  
        : lang == "fr" ? 
          <div className="font-extralight">
            <h1 className="text-5xl font-semibold pb-5">Disclaimer</h1>
            <p className="mb-10">
              Les sites web<br/>
              <b>student-rooms.com</b><br/>
              <b>opkotin.be</b><br/>
              <b>opkotinantwerpen.be</b><br/>
              <b>opkotinbrussel.be</b><br/>
              <b>opkotingent.be</b><br/>
              <b>opkotinhasselt.be</b><br/>
              <b>opkotinkortrijk.be</b><br/>
              <b>opkotinleuven.be</b><br/>
              <b>inbruggeopkot.be</b><br/>
              <br/>
              sont gérés par :
              <img src={UELOGO} />
              <a href="https://upgrade-estate.be/fr/" target="_blank" className="text-indigo-600 font-normal">Upgrade Estate SA</a>, Burggravenlaan 31, B-9000 Gand (Belgique)
            </p>
            <div className="text-sm">
              L’utilisation d’un ou plusieurs de ces sites implique votre acceptation intégrale des articles suivants. En sus de ces derniers, toutes les activités commerciales considérées sont soumises aux conditions générales d’Upgrade Estate SA.
              <br/><br/>
              Les collaborateurs de ‘Op Kot in’ + ‘student-rooms’ font leur possible pour contrôler au mieux l’exactitude des informations publiées sur ces sites web. Cependant, toutes les données et informations communiquées sont totalement sans engagement. Ni Upgrade Estate SA, ni les collaborateurs en charge du site web ne peuvent être tenus pour responsables d’inexactitudes éventuelles. Nous vous recommandons dès lors de vérifier l’exactitude des informations proposées avant de souscrire un contrat avec un bailleur.
              <br/><br/>
              Par ailleurs, ‘Op Kot in’ + ‘student-rooms’ réaffirme clairement n’avoir aucun lien avec un quelconque service de logement. Les informations figurant sur ce site web sortent dès lors du cadre des critères de qualité et de sélection du service des logements.
              <br/><br/>
              Tous les droits intellectuels relatifs à la mise en forme de ce site web, aux logos et à la structure du site et des bases de données appartiennent à Upgrade Estate SA.
              <br/><br/>
              Le contenu de ce site web est protégé par la législation sur les droits d’auteur. Tous droits réservés.
              <br/><br/>
              Aucune partie de ce site web ne peut être reproduite et/ou rendue publique via internet, par tout moyen d’impression, photocopie, microfilm ou de quelque autre manière que ce soit sans l’accord écrit préalable d’Upgrade Estate.
              <br/><br/>
              Toute forme de deeplinking, en particulier l’inline linking et/ou le framed linking, qui contreviendrait au droit des marques ou des bases de données, au droit exclusif de reproduction et à la loi sur les pratiques commerciales et pour laquelle aucune autorisation écrite n’aurait été délivrée par Upgrade Estate pourra entraîner des sanctions pénales par tous moyens juridiques, conformément au droit pénal ordinaire et spécial.
              <br/><br/>
              Les personnes qui ajoutent des photos et/ou des illustrations à leurs annonces s’engagent à n’utiliser exclusivement que des illustrations et des photos ne contrevenant pas à la législation sur les droits d’auteur ni aux bonnes moeurs.
              Bien qu’Upgrade Estate SA fasse tout son possible pour assurer une accessibilité à son site web 24 heures sur 24 et 7 jours par semaine, nous nous réservons le droit d’en limiter l’accès ou de le fermer sans avis préalable. Ni Upgrade Estate SA, ni les collaborateurs en charge du site web ne peuvent être tenus pour responsables des conséquences d’une interruption potentiel de cet accès.
              <br/><br/>
              Nous nous réservons également le droit de refuser sans avis préalable l’accès au site ou à certaines de ses parties à certains individus spécifiquement identifiés.
              <br/><br/>
              Upgrade Estate SA n’endosse aucune responsabilité quant aux informations figurant sur les sites web ou pages accessibles via un lien posté sur le présent site web. La responsabilité de l’exactitude et de la légalité du contenu de ces autres sites ou pages incombe intégralement aux gestionnaires des sites web ou des pages en question.
              <br/><br/>
              Upgrade Estate SA ne recueille pas de données personnelles, sauf si ces dernières lui sont communiquées volontairement. En s’enregistrant comme gestionnaire de kots, en s’inscrivant à la newsletter ou en envoyant un e-mail, l’utilisateur ou le visiteur autorise Upgrade Estate SA à sauvegarder ses données personnelles dans un fichier appartenant à Upgrade Estate SA et destiné au profilage automatisé des utilisateurs, à la gestion de sa clientèle, aux études de marché et au publipostage. Les données communiquées peuvent être utilisées à des fins promotionnelles, y compris par des tiers. Vous disposez d’un droit de regard et de correction éventuelle de vos données personnelles. Vous avez également le droit de vous opposer sans frais à l’utilisation de vos données à des fins de marketing direct. Vous pouvez faire valoir votre droit de regard, de correction ou d’opposition via notre formulaire de contact. En cas de modification ou de suppression de vos données, nous ne pouvons toutefois être tenus pour responsables des conséquences potentielles dans l’hypothèse où les services auxquels vous pouvez accéder sur le site web ne fonctionneraient plus comme il se doit.
              <br/><br/>
              En cas de différend éventuel, les parties concernées chercheront de prime abord à parvenir à un accord concerté. Si toutefois l’affaire devait être portée devant la justice, la juridiction belge et le tribunal de Gand sont seuls compétents.
            </div>
          </div> 
        :
          <div className="font-extralight">
            <h1 className="text-5xl font-semibold pb-5">Disclaimer</h1>
            <p className="mb-10">
              The websites<br/>
              <b>student-rooms.com</b><br/>
              <b>opkotin.be</b><br/>
              <b>opkotinantwerpen.be</b><br/>
              <b>opkotinbrussel.be</b><br/>
              <b>opkotingent.be</b><br/>
              <b>opkotinhasselt.be</b><br/>
              <b>opkotinkortrijk.be</b><br/>
              <b>opkotinleuven.be</b><br/>
              <b>inbruggeopkot.be</b><br/>
              <br/>
              are managed by:
              <img src={UELOGO} />
              <a href="https://upgrade-estate.be/en/" target="_blank" className="text-indigo-600 font-normal">UPGRADE ESTATE NV</a>, Burggravenlaan 31, B-9000 Ghent (Belgium)
            </p>
            <div className="text-sm">
              Use of one or more of these sites implies that you accept the following articles in their entirety. For all commercial matters, the general terms and conditions of Upgrade Estate NV hold in addition to these articles.
              <br/><br/>
              The employees of ‘Op Kot in’ + ‘student-rooms’ do their best to check the accuracy of the information on this website insofar as possible. All data and information are however completely non-binding. Neither Upgrade Estate NV nor the employees of this website can be held liable for any inaccuracies. We also advise you to examine the accuracy of the information provided before you conclude an agreement with a landlord.
              <br/><br/>
              Furthermore, ‘Op Kot in’ + ‘student-rooms’ wants to make it clear that there is no connection with any Housing Service. Thus the information on this website is not subject to the quality and selection criteria of the Housing Service.
              <br/><br/>
              All intellectual rights with regard to the design of this website, the logos and the structure of the website and databanks belong to Upgrade Estate NV.
              <br/><br/>
              The content of this website is protected by copyright legislation. All rights are reserved.
              <br/><br/>
              Nothing from this website may be reproduced and/or made public via the internet, by means of printing, photocopies, microfilm, or in any other way whatsoever, without prior written permission from Upgrade Estate.
              <br/><br/>
              Any form of deep linking, in particular inline linking and/or framed linking, that conflicts with trademark or databank rights, the exclusive right to reproduction and the law on trade practices, and for which no written permission has been granted by Upgrade Estate, can lead to penalties by all legal means in accordance with general and special criminal law.
              <br/><br/>
              Persons who add photos and/or images to their offers undertake to use exclusively images and photos that do not conflict with copyright law and common decency.
              <br/><br/>
              Although Upgrade Estate NV does its best to keep the website accessible 24 hours a day, 7 days a week, we reserve the right to limit or cut off access to the website without prior notice. Upgrade Estate NV or the employees of the website cannot be held liable for consequences of any interruption of access.
              <br/><br/>
              We also reserve the right to specifically deny certain people access to the website or parts thereof without prior notice.
              <br/><br/>
              Upgrade Estate NV assumes no responsibility whatsoever for information on websites or pages to which a link is made from this website. Responsibility for the accuracy and legality of the content of these other websites or pages lies entirely with the managers of these websites or pages.
              <br/><br/>
              Upgrade Estate NV collects personal data only if they are provided on a voluntary basis. By registering as a landlord, signing up for the newsletter, or sending an e-mail, a user or visitor gives Upgrade Estate NV permission to store his or her personal data in a file of Upgrade Estate NV for the purposes of automated user profiling, customer management, market research and direct mail by post. The data provided can be used for promotional purposes by third parties as well. You have the right to inspect and make any correction to your personal data in question. You have the right, free of charge, to object to processing of your data for direct marketing purposes. You can submit your request for inspection, correction or objection via our contact form. If your information is changed or removed, we are however not responsible for the possible consequences if the services on the website no longer function properly for you.
              <br/><br/>
              In the event of any disputes, a mutual agreement will first be sought between the various parties. If legal action is appropriate, Belgian law and the courts of Ghent alone are valid.
            </div>
          </div>  
        }
      </div>          
      <Footer />
    </div>
  );
}
