//import packages
import { useTranslation } from 'react-i18next';

//import components
import SecondaryMenu from "../../components/SecondaryMenu.component";
import Footer from "../../components/Footer.component";
import { useEffect, useState } from 'react';

export default function Tips() {
  const { t, i18n } = useTranslation()
  const [lang, setLang] = useState();

  useEffect(() => {
    document.title = "Tips"
    setLang(i18n.language);
  }, [i18n.language]);

  return (
    <div className="bg-grey-100">
      <SecondaryMenu />
      <div className="max-w-6xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        {lang == "nl" ?
          <>
              <p className="font-bold">Registreer je GRATIS op student-rooms.com</p>
              <p className="font-extralight mb-5">Dit is niet verplicht en je kan onze zoekmachine ook gebruiken zonder registratie. Maar door je te registreren kan je je favoriete koten bewaren en ook je zoekopdrachten beheren. Zo kan je vermijden om steeds weer dezelfde zoekopdrachten in te geven.</p>

              <p className="font-bold">Denk goed na over het soort kot!</p>
              <p className="font-extralight mb-5">Er zijn verschillende woonvormen mogelijk. Beslis op voorhand of je op een gewoon kot, een studio, een appartement of een residentiekamer wil zitten. Elke woonvorm heeft zo z’n eigen voor- en nadelen. Bezoek op voorhand verschillende koten en praat eens met de huidige bewoners als <b>je nog twijfelt.</b></p>

              <p className="font-bold">Kijk goed uit waar je een kot huurt!</p>
              <p className="font-extralight mb-5">De ligging van jouw kot is best wel belangrijk. Kijk bijvoorbeeld na of je gemakkelijk aan jouw faculteit geraakt. Ligt je kot op een rustige locatie? Als er bijvoorbeeld een café in de buurt is, moet je er rekening mee houden dat je ‘s avonds wel eens wat lawaaihinder kan hebben. Een bus stopplaats kan ook voor het nodige lawaai zorgen. Het is ook altijd leuk dat je snel bij iemand kan binnenspringen, dus kijk ook eens rond of er nog studenten in jouw buurt zitten.</p>

              <p className="font-bold">Kijk alles goed na voor je iets tekent!</p>
              <p className="font-extralight mb-5">Als je naar koten gaat kijken, zorg dan dat je zoveel mogelijk op de hoogte bent van alle mogelijke gebreken en extra’s. Bezoek verschillende aanbiedingen om te vergelijken. Kijk na of het kot en het gebouw waarin het zich bevindt, voorzien is van de nodige brandbeveiliging en hygiëne. Praat eens met bewoners, want de sfeer is ook erg belangrijk. Probeer altijd iemand mee te nemen om koten te gaan bezichtigen. Met twee zie je altijd meer.</p>

              <p className="font-bold">Zorg dat je weet wat je zal betalen!</p>
              <p className="font-extralight mb-5">Bespreek vooraf goed met de verhuurder wat je doorheen het jaar allemaal zal moeten betalen. Natuurlijk is er de maandelijkse huur, maar informeer je ook goed naar de maandelijkse kosten, de huurwaarborg en eventuele extra kosten (onderhoud, stadstaks, abonnementen, …). Stel ook altijd voor om de huurwaarborg op een gezamenlijke geblokkeerde rekening te zetten. Zo is jouw waarborg altijd veilig. Vraag jouw bank om meer informatie hierover.</p>

              <p className="font-bold">Zorg voor een degelijk contract!</p>
              <p className="font-extralight mb-5">Lees jouw contract altijd zeer goed na voor je het tekent en zorg dat je alles begrijpt en dat alles duidelijk is. Daarmee bespaar je jou een heleboel problemen mocht er ooit iets mislopen. Kotbazen stellen dikwijls hun eigen contracten op. Daarin proberen ze zichzelf natuurlijk zo goed mogelijk in te dekken tegen allerlei verantwoordelijkheden. Lees jouw contract er goed op na dat ook jezelf voldoende bent ingedekt.</p> 
          </>  
        : lang == "fr" ? 
        <>
              <p className="font-bold">Enregistrez-vous sur student-rooms.com (sans engagement)</p>
              <p className="font-extralight mb-5">Ce ne est pas obligatoire, mais en vous inscrivant , vous pouvez facilement garder trace de vos chambres d’étudiants préférés et recherches enregistrées .</p>

              <p className="font-bold">Réfléchissez bien au type de kot souhaité !</p>
              <p className="font-extralight mb-5">Plusieurs types de logement sont possibles. Déterminez au préalable si vous désirez un kot ordinaire, un studio, un appartement ou une chambre en résidence. Chacun de ces logements présente des avantages et des inconvénients. Avant de vous décider, visitez plusieurs kots et si vous hésitez encore, discutez avec les occupants.</p>

              <p className="font-bold">Réfléchissez bien à l’endroit où vous louez un kot !</p>
              <p className="font-extralight mb-5">La localisation de votre kot est capitale. Vérifiez par exemple si vous pouvez rejoindre facilement votre faculté. Le kot est-il situé dans un endroit calme ? S’il y a un café dans les environs, vous devez tenir compte de la possibilité d’être gêné par le bruit en soirée. Un arrêt d’autobus peut également entraîner certaines nuisances sonores. Enfin, il est utile de pouvoir rapidement joindre quelqu’un dans l’entourage; assurez-vous donc que d’autres étudiants habitent dans le coin.</p>

              <p className="font-bold">Relisez attentivement toutes les clauses avant de signer le moindre document !</p>
              <p className="font-extralight mb-5">Lorsque vous visitez des kots, essayez autant que possible de déceler tous les défauts existants et tout ce qu’on ne vous dit pas. Visitez plusieurs logements pour pouvoir comparer. Assurez-vous que le kot et le bâtiment où il est aménagé disposent des protections anti-incendie et des équipements d’hygiène nécessaires. Entretenez-vous avec les habitants : il est extrêmement important de “sentir” l’ambiance qui y règne. Essayez toujours de vous faire accompagner pour visiter un kot : on voit plus de choses à deux.</p>

              <p className="font-bold">Évitez toute ambiguïté sur le prix à payer !</p>
              <p className="font-extralight mb-5">Mettez-vous d’accord au préalable avec le bailleur sur tout ce que vous devrez payer en cours d’année. Il y a bien sûr le loyer mensuel, mais informez-vous aussi clairement que possible sur les charges mensuelles, la garantie locative et les frais supplémentaires éventuels (entretien, taxe urbaine, abonnements,…). Proposez toujours de verser la garantie locative sur un compte commun bloqué. Ainsi, votre garantie restera à l’abri des convoitises. Pour de plus amples informations à ce sujet, interrogez votre banque.</p>

              <p className="font-bold">Veillez à disposer d’un contrat en bonne et due forme !</p>
              <p className="font-extralight mb-5">Lisez toujours attentivement votre contrat avant de le signer et assurez-vous que vous avez tout compris et qu’il ne subsiste aucune zone d’ombre. Vous vous épargnerez ainsi une foule de problèmes si quelque chose devait tourner à l’aigre. Les gestionnaires de kots rédigent souvent leurs propres contrats, en tentant naturellement de se prémunir autant que possible contre un maximum de responsabilités. Lisez donc minutieusement votre contrat et soyez attentif à être vous-même suffisamment couvert.</p> 
        </>
        :
        <>
              <p className="font-bold">Register for free on student-rooms.com </p>
              <p className="font-extralight mb-5">It’s not mandatory, but by registering, you can easily keep track of your favourite student rooms and saved searches.</p>

              <p className="font-bold">Think carefully about the type of room!</p>
              <p className="font-extralight mb-5">Various types of living arrangements are possible. Decide beforehand whether you want to live in a regular room, a studio, a flat or a residential room. Every living arrangement has its own advantages and disadvantages. Visit different rooms beforehand and talk to the current residents if you still have doubts.</p>

              <p className="font-bold">Take care where you rent a room!</p>
              <p className="font-extralight mb-5">The location of your room is very important. For example, check whether you can reach your department easily. Is your room in a quiet location? If for example there is a café in the neighbourhood, you must take into account that you can have a good deal of noise nuisance in the evenings. A bus stop can also cause quite a bit of noise. It’s always nice too to be able to drop in on someone quickly, so look around to see whether there are other students in your neighbourhood.</p>

              <p className="font-bold">Examine everything carefully before you sign anything!</p>
              <p className="font-extralight mb-5">When you go to look at rooms, ensure that you are aware insofar as possible of all the possible shortcomings and extras. Visit different possibilities to compare. Check whether the room and the building it is in are equipped with the necessary fire protection and hygiene. Talk with residents, because the atmosphere is also very important. Always try to take someone along to go look at rooms. Two people always see more.</p>

              <p className="font-bold">Be sure that you know what you will pay!</p>
              <p className="font-extralight mb-5">Discuss thoroughly with the landlord in advance what all you will have to pay throughout the year. Of course there is the monthly rent, but be fully aware of the monthly costs, the security deposit, and any extra costs (maintenance, city tax, subscriptions, etc.) too. Also always propose putting the security deposit in a common blocked account. This way your deposit is always safe. Ask your bank for more information on this.</p>

              <p className="font-bold">Be sure you have a sound contract!</p>
              <p className="font-extralight mb-5">Always read over your contract carefully before you sign it and ensure that you understand everything and everything is clear. This way you save yourself a lot of problems if anything should ever go wrong. Landlords often draft their own contracts. In these they naturally try to cover themselves against all sorts of liabilities insofar as possible. Read over your contract and be sure that you yourself are adequately covered.</p> 
        </>
        }
      </div>          
      <Footer />
    </div>
  );
}
