import SmallLogo from "../assets/img/logo-small.png";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { Disclosure } from "@headlessui/react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import { ChevronRightIcon } from "@heroicons/react/solid";
import { useRecoilState } from "recoil";

import { useState } from "react";
import { SearchIcon } from "@heroicons/react/solid";
import { Combobox } from "@headlessui/react";

import { useTranslation } from "react-i18next";

//services
import listingsService from "../services/listings.service";

//import datastores
import { cityState } from "../data/city.atom";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function MiniMenu({ data }) {
  const { t, i18n } = useTranslation();

  const [query, setQuery] = useState("");
  const [results, setResults] = useState();
  const [cities, setCities] = useRecoilState(cityState);

  const filteredCities =
    query === ""
      ? []
      : cities.filter((city) => {
          return city.name.toLowerCase().includes(query.toLowerCase());
        });

  useEffect(() => {
    listingsService
      .getSearch(query)
      .then((response) => {
        setResults(response.data);
      })
      .catch((error) => {});
  }, [query]);

  return (
    <div className="z-50 w-full border-b bg-gray-50">
      <Disclosure as="nav" className="transparant w-full">
        {({ open }) => (
          <>
            <div className="mx-auto px-3 sm:px-4 lg:px-5">
              <div className="flex justify-between h-16">
                <div className="flex">
                  <div className="flex-shrink-0 flex items-center">
                    <Link to={"/"}>
                      <img src={SmallLogo} className="w-10" />
                    </Link>
                    <div className="ml-8">
                      {data == "search" ? (
                        <Combobox
                          as="div"
                          className="max-w-xl mx-auto transform divide-y divide-gray-100 overflow-hidden rounded-xl bg-white shadow-sm ring-1 ring-black ring-opacity-5 transition-all absolute -mt-6 z-50"
                          onChange={(result) =>
                            result.nid
                              ? (window.location =
                                  result.type +
                                  "/" +
                                  result.city +
                                  "/" +
                                  result.address +
                                  "/" +
                                  result.nid)
                              : (window.location = result.name)
                          }
                        >
                          <div className="relative">
                            <SearchIcon
                              className="pointer-events-none absolute top-3.5 left-4 h-5 w-5 text-gray-400"
                              aria-hidden="true"
                            />
                            <Combobox.Input
                              className="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-gray-800 placeholder-gray-400 focus:ring-0 sm:text-sm"
                              placeholder={t("home.search.search")}
                              onChange={(event) => {
                                if (event.target.value.length > 2) {
                                  setQuery(event.target.value);
                                }
                              }}
                            />
                          </div>

                          {query !== "" && results.length > 0 && (
                            <Combobox.Options
                              static
                              className="max-h-72 scroll-py-2 overflow-y-auto py-2 text-sm text-gray-800 capitalize text-left"
                            >
                              {filteredCities.map((city) => (
                                <Combobox.Option
                                  key={city["name"]}
                                  value={city}
                                  className={({ active }) =>
                                    classNames(
                                      "cursor-default select-none px-4 py-2",
                                      active && "bg-indigo-600 text-white"
                                    )
                                  }
                                >
                                  🌎{" "}
                                  <span className="font-medium ml-1">
                                    {city["name"]}
                                  </span>
                                </Combobox.Option>
                              ))}
                              {results.map((result) => (
                                <Combobox.Option
                                  key={result["nid"]}
                                  value={result}
                                  className={({ active }) =>
                                    classNames(
                                      "cursor-default select-none px-4 py-2",
                                      active && "bg-indigo-600 text-white"
                                    )
                                  }
                                >
                                  📍{" "}
                                  <span className="font-medium">
                                    {result["city"]}
                                  </span>{" "}
                                  - {result["title"]}
                                </Combobox.Option>
                              ))}
                            </Combobox.Options>
                          )}

                          {query !== "" && results.length === 0 && (
                            <p className="p-4 text-sm text-gray-500">
                              {t("home.search.noresults")}...
                            </p>
                          )}
                        </Combobox>
                      ) : (
                        <>
                          <nav className="flex" aria-label="Breadcrumb">
                            <ol
                              role="list"
                              className="flex items-center lg:space-x-4"
                            >
                              {data.map((page, index) => (
                                <li key={index}>
                                  <div className="flex items-center">
                                    {index != 0 ? (
                                      <ChevronRightIcon
                                        className="flex-shrink-0 h-5 w-5 text-gray-400"
                                        aria-hidden="true"
                                      />
                                    ) : (
                                      <></>
                                    )}
                                    <a
                                      href={page.href}
                                      className="lg:ml-4 text-sm font-medium text-indigo-600 hover:text-gray-700 capitalize"
                                    >
                                      {index == 0 ? (
                                        <Link to={"/" + page}>{page}</Link>
                                      ) : index == 1 ? (
                                        <Link to={"/" + data[0] + "/" + page}>
                                          {page}
                                        </Link>
                                      ) : index == 2 ? (
                                        <Link
                                          to={
                                            "/" +
                                            data[0] +
                                            "/" +
                                            data[1] +
                                            "/" +
                                            page
                                          }
                                        >
                                          {page}
                                        </Link>
                                      ) : (
                                        <Link to={"/"}>{page}</Link>
                                      )}
                                    </a>
                                  </div>
                                </li>
                              ))}
                            </ol>
                          </nav>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div className="hidden sm:ml-6 sm:flex sm:items-center">
                  <a
                    href={
                      process.env.REACT_APP_BACKEND_URL +
                      i18n.resolvedLanguage +
                      "/node/add/listing"
                    }
                    className="text-indigo-600 ring-indigo-600 p-2 rounded-sm hover:text-gray-700 hover:ring-gray-700 ring-2 text-sm"
                  >
                    {t("home.menu.add")}
                  </a>
                  <a
                    href={process.env.REACT_APP_BACKEND_URL}
                    className="text-indigo-600 p-2 pl-5 text-sm hover:text-gray-700"
                  >
                    {t("home.menu.profile")}
                  </a>
                </div>
                <div className="-mr-2 flex items-center sm:hidden">
                  {/* Mobile menu button */}
                  <Disclosure.Button className="inline-flex items-center justify-center p-2 text-black focus:outline-none">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
              </div>
            </div>

            <Disclosure.Panel className="sm:hidden absolute w-full z-50">
              <div className="mt-5 pb-3 border-t border-gray-200 bg-white mx-5 rounded-md shadow-md">
                <div className="mt-3 space-y-1">
                  <Disclosure.Button
                    as="a"
                    href={
                      process.env.REACT_APP_BACKEND_URL +
                      i18n.resolvedLanguage +
                      "/node/add/listing"
                    }
                    className="block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100"
                  >
                    {t("home.menu.add")}
                  </Disclosure.Button>
                  <Disclosure.Button
                    as="a"
                    href={process.env.REACT_APP_BACKEND_URL}
                    className="block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100"
                  >
                    {t("home.menu.profile")}
                  </Disclosure.Button>
                </div>
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </div>
  );
}
