import i18n from "i18next";
import api from "./api/api";

class ListingService {
  getPreviews(data, page) {
    return api.post("/jsonapi/get-listings/" + i18n.resolvedLanguage + "/?page=" + page, data);
  }

  getEntity(data) {
    return api.post("/jsonapi/get-listing/" + i18n.resolvedLanguage + "/", data);
  }

  getSearch(data) {
    return api.post("/jsonapi/search-keywords/", {
      keywords: data,
    });
  }

  submitContact(nid, message, name, mail, phone, captcha) {
    return api.post("/jsonapi/room-send-email/", {
      "nid": nid,
      "message": message,
      "name": name,
      "email": mail,
      "phone": phone,
      "g-recaptcha-response": captcha
    });
  }
}

export default new ListingService();
