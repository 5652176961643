import GoogleMapReact from "google-map-react";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams, useNavigate } from "react-router-dom";

//components in chronologische volgorde
import ContactCard from "../components/ContactCard.component";
import Footer from "../components/Footer.component";
import ImageSlider from "../components/ImageSlider.component";
import InfoBar from "../components/InfoBar.component";
import Loading from "../components/Loading.component";
import MiniMenu from "../components/MiniMenu.component";

//services
import listingsService from "../services/listings.service";

const MarkerComponent = ({ price }) => (
  <div className="bg-indigo-600 text-white w-10 h-auto text-center py-1 rounded-sm font-medium">
    € {price}
  </div>
);

export default function Listing() {
  let { id } = useParams();
  const { t, i18n } = useTranslation();

  const [listing, setListing] = useState();
  const LocationREF = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    listingsService
      .getEntity({ nid: id })
      .then((response) => {
        document.title =
          response.data.type +
          " in " +
          response.data.street.charAt(0).toUpperCase() +
          response.data.street.slice(1) +
          ", " +
          response.data.city.charAt(0).toUpperCase() +
          response.data.city.slice(1) +
          " · " +
          response.data.title;
        setListing(response.data);
      })
      .catch((error) => {
        window.location.href = "/sorry/";
      });
  }, [i18n.language]);

  return (
    <div className="bg-gray-50">
      {listing ? (
        <>
          <MiniMenu data={[listing.type, listing.city, listing.street]} />
          <ImageSlider data={listing.photos} />
          <div className="flex flex-wrap md:w-2/3 w-4/5 mr-auto ml-auto">
            <div className="w-full lg:w-3/5">
              <h1 className="text-3xl font-medium pt-8 capitalize">
                {listing.street} {listing.number}{" "}
                <span className="text-2xl text-gray-500">
                  • {listing.postal_code} {listing.city}
                </span>
              </h1>
              <InfoBar
                price={listing.price}
                type={listing.type}
                size={listing.size}
                date={listing.date}
              />
              <div className="mt-8">
                <p className="font-bold uppercase">{listing.title}</p>
                <p className="font-light">{listing.description}</p>
              </div>
              <div className="mt-8">
                <p className="font-bold">{t("listing.info")}</p>
                <div className="flex flex-wrap font-light">
                  { listing.amenities &&
                  listing.amenities.map((amenity) => (
                    <div
                      className="md:w-1/2 lg:w-1/3 w-full flex space-x-2 items-center"
                      key={amenity.name}
                    >
                      <img
                        src={process.env.REACT_APP_BACKEND_URL + amenity.icon}
                        className="w-4 h-auto"
                      />
                      <span>{amenity.name}</span>
                    </div>
                  ))}
                  { listing.facilities &&
                  listing.facilities.map((facility) => (
                    <div
                      className="md:w-1/2 lg:w-1/3 w-full flex space-x-2 items-center"
                      key={facility.name}
                    >
                      <img
                        src={process.env.REACT_APP_BACKEND_URL + facility.icon}
                        className="w-4 h-auto"
                      />
                      <span>{facility.name}</span>
                    </div>
                  ))}
                </div>
              </div>
              <div className="mt-8 mb-8" ref={LocationREF}>
                <p className="font-bold">{t("listing.location")}</p>
                <div className="mt-4" style={{ height: 300 }}>
                  <GoogleMapReact
                    bootstrapURLKeys={{
                      key: "AIzaSyDbpR_xXxZ8kPr31rz9pGHx9Z59aEiTRGg",
                    }}
                    defaultCenter={{ lat: 50.5286224, lng: 4.4780462 }}
                    defaultZoom={8}
                  >
                    <MarkerComponent
                      lat={listing.latitude}
                      lng={listing.longitude}
                      price={parseInt(listing.price)}
                    />
                  </GoogleMapReact>
                </div>
              </div>
            </div>
            <div className="lg:w-2/5 w-full">
              <ContactCard
                nid={id}
                price={listing.price}
                extra={listing.extra_costs}
                LocationREF={LocationREF}
                location={
                  listing.street +
                  " " +
                  listing.number +
                  ", " +
                  listing.postal_code +
                  " " +
                  listing.city
                }
              />
            </div>
            <div className="mt-8 hidden" id="more">
              <p className="font-bold">In de buurt</p>
              <ul role="list" className="m-5 overflow-x-scroll">
                <div className="w-1/3 float-left"></div>
              </ul>
            </div>
          </div>
        </>
      ) : (
        <Loading />
      )}
      <Footer />
    </div>
  );
}
