import { ReactComponent as BigLogo } from "../assets/img/logo-big.svg";
import { Link } from "react-router-dom";
import { Disclosure } from "@headlessui/react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import { useTranslation } from 'react-i18next';


function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function SecondaryMenu() {
  const { t, i18n } = useTranslation();

  return (
    <div className="z-20 w-full">
      <Disclosure as="nav" className="transparant w-full">
        {({ open }) => (
          <>
            <div className="mx-auto pt-5 px-3 sm:px-4 lg:px-5">
              <div className="flex justify-between h-16">
                <div className="flex">
                  <Link to={"/"}>
                    <div className="flex-shrink-0 flex items-center">
                      <BigLogo
                        fill="#3a5beb"
                        className="block h-20 w-auto"
                      />
                    </div>
                  </Link>
                </div>
                <div className="hidden sm:ml-6 sm:flex sm:items-center">
                  <a
                    href={
                      process.env.REACT_APP_BACKEND_URL + i18n.resolvedLanguage + "/node/add/listing"
                    }
                    className="text-indigo-600 p-2 rounded-sm hover:text-white hover:bg-indigo-600 ring-2 ring-indigo-600 text-sm"
                  >
                    {t('home.menu.add')}
                  </a>
                  <a
                    href={process.env.REACT_APP_BACKEND_URL}
                    className="text-indigo-600 p-2 pl-5 text-sm"
                  >
                    {t('home.menu.profile')}
                  </a>
                </div>
                <div className="-mr-2 flex items-center sm:hidden">
                  {/* Mobile menu button */}
                  <Disclosure.Button className="inline-flex items-center justify-center p-2 text-indigo-600 focus:outline-none">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
              </div>
            </div>

            <Disclosure.Panel className="sm:hidden absolute w-full">
              <div className="mt-5 pb-3 border-t border-gray-200 bg-white mx-5 rounded-md shadow-md">
                <div className="mt-3 space-y-1">
                  <Disclosure.Button
                    as="a"
                    href={
                      process.env.REACT_APP_BACKEND_URL + i18n.resolvedLanguage + "/node/add/listing"
                    }
                    className="block px-4 py-2 text-base font-medium text-indigo-600 hover:indigo-800 hover:bg-gray-100"
                  >
                    {t('home.menu.add')}
                  </Disclosure.Button>
                  <Disclosure.Button
                    as="a"
                    href={process.env.REACT_APP_BACKEND_URL}
                    className="block px-4 py-2 text-base font-medium text-indigo-600 hover:text-indigo-800 hover:bg-gray-100"
                  >
                    {t('home.menu.profile')}
                  </Disclosure.Button>
                </div>
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </div>
  );
}
