import i18n from "i18next";
import api from "./api/api";

class CityService {
  getSlider() {
    return api.get("/jsonapi/get-slider/");
  }

  getCities() {
    return api.get("/jsonapi/get-cities/" + i18n.resolvedLanguage + "/");
  }
}

export default new CityService();
