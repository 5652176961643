import { useTranslation } from 'react-i18next';

export default function InfoBar({ price, type, size, date }) {
  const { t } = useTranslation();

  return (
    <div className="mt-8 shadow-sm">
      <div className="flex flex-wrap bg-white border rounded-lg text-center">
        <div className="md:w-1/4 w-1/2 border-r py-3">
          <h4 className="text-gray-400 text-xs uppercase">{t('listing.infobar.price')}</h4>
          <h3 className="font-medium text-indigo-600 text-lg pt-1">
            € {price}
          </h3>
        </div>
        <div className="md:w-1/4 w-1/2 py-3">
          <h4 className="text-gray-400 text-xs uppercase">{t('listing.infobar.type')}</h4>
          <h3 className="font-medium text-indigo-600 text-lg pt-1">{type}</h3>
        </div>
        <div className="md:w-1/4 w-1/2 border-l py-3">
          <h4 className="text-gray-400 text-xs uppercase">{t('listing.infobar.size')}</h4>
          <h3 className="font-medium text-indigo-600 text-lg pt-1">
            {size} m<sup>2</sup>
          </h3>
        </div>
        <div className="md:w-1/4 w-1/2 border-l py-3">
          <h4 className="text-gray-400 text-xs uppercase">{t('listing.infobar.from')}</h4>
          <h3 className="font-medium text-indigo-600 text-lg pt-1">{date}</h3>
        </div>
      </div>
    </div>
  );
}
