//import packages
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

//import components
import SecondaryMenu from "../../components/SecondaryMenu.component";
import Footer from "../../components/Footer.component";
import UELOGO from '../../assets/img/UPGRADE-logo.png'

export default function Contact() {
  const { t, i18n } = useTranslation();
  const [lang, setLang] = useState();

  useEffect(() => {
    document.title = "Contact"
    setLang(i18n.language);
  }, [i18n.language]);
  
  return (
    <div className="bg-grey-100">
      <SecondaryMenu />
      <div className="max-w-6xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 mb-20">
        {lang == "nl" ?
          <div className="font-extralight">
            <h1 className="text-5xl font-semibold pb-5">Contact</h1>
            <p className="mt-2">
              Welkom bij student-rooms en opkotin,<br/>
              een initiatief van <a href="https://upgrade-estate.be/nl/" target="_blank" className="text-indigo-600">Upgrade Estate NV</a>.
            </p>
            <p className="mt-5">
             Sinds 2006 beheren wij de grootste referentie zoekmachines voor studentenkamers in Vlaanderen.
            </p>
            <p className="mt-5">
              Aan onze gratis service is geen support verbonden, noch online, noch telefonisch.
            </p>
            <img src={UELOGO} className="mt-5" />
            <p className="mt-5">
              <b>Upgrade Estate NV</b><br />
              Burggravenlaan 31<br />
              9000 Gent
            </p>
            <p className="mt-5">
              Let op! Zoals ook al in de <Link to="/disclaimer/" className="text-indigo-600">disclaimer</Link> uitgedrukt, stelt <b>Upgrade Estate NV</b> enkel de website ter beschikking. Wij zijn niet verantwoordelijk voor de inhoud van de website en mengen ons op geen enkele manier in handelingen tussen verhuurders, huurders en potentiële huurders.
            </p>
          </div>
        : lang == "fr" ? 
          <div className="font-extralight">
            <h1 className="text-5xl font-semibold pb-5">Contact</h1>
            <p className="mt-2">
              Bienvenue sur student-rooms.com et opkotin,<br/>
              une initiative <a href="https://upgrade-estate.be/fr/" target="_blank" className="text-indigo-600">d’Upgrade Estate SA</a>.
            </p>
            <p className="mt-5">
              Depuis 2006, nous gérons les moteurs de recherche qui font référence pour la mise à disposition de chambres d’étudiants en Flandre.
            </p>
            <p className="mt-5">
               Nous fournissons pas de support en ligne ou par téléphone.
            </p>
            <img src={UELOGO} className="mt-5" />
            <p className="mt-5">
              <b>Upgrade Estate NV</b><br />
              Burggravenlaan 31<br />
              9000 Gent<br />
              Belgique
            </p>
            <p className="mt-5">
            Attention ! Comme indiqué dans le <Link to="/disclaimer/" className="text-indigo-600">disclaimer</Link> <b>Upgrade Estate SA</b> se charge uniquement de mettre ce site web à la disposition du public. Nous ne sommes pas responsables de son contenu et n’intervenons en aucune manière dans les tractations entre bailleurs, locataires et locataires potentiels.
            </p>
          </div>
        :
          <div className="font-extralight">
            <h1 className="text-5xl font-semibold pb-5">Contact</h1>
            <p className="mt-2">
              Welcome to student-rooms.com and opkotin,<br/>
              an initiative of <a href="https://upgrade-estate.be/en/" target="_blank" className="text-indigo-600">Upgrade Estate NV</a>.
            </p>
            <p className="mt-5">
              Since 2006 we have managed the leading search engines for student rooms in Flanders.
            </p>
            <p className="mt-5">
              Our free service does not provide support, either online or by telephone.
            </p>
            <img src={UELOGO} className="mt-5" />
            <p className="mt-5">
              <b>Upgrade Estate NV</b><br />
              Burggravenlaan 31<br />
              9000 Ghent<br />
              BELGIUM
            </p>
            <p className="mt-5">
              Note! As already also stated in the <Link to="/disclaimer/" className="text-indigo-600">disclaimer</Link>, <b>Upgrade Estate NV</b> only makes the website available. We are not responsible for the content of the website and do not participate in any way in dealings between landlords, tenants and potential tenants.
            </p>
          </div>
        }
      </div>          
      <Footer />
    </div>
  );
}
