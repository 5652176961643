import React from "react";
import ReactDOM from "react-dom";
import ReactGA from 'react-ga';
import { BrowserRouter } from "react-router-dom";

import "./index.css";
import App from "./App";

import { RecoilRoot } from "recoil";
import { CookiesProvider } from 'react-cookie';

import './i18n';


const TRACKING_ID = "G-WKLMEBLRRB";
ReactGA.initialize(TRACKING_ID);

ReactDOM.render(
  <React.StrictMode>
    <RecoilRoot>
      <CookiesProvider>
        <BrowserRouter>
          <App /> 
        </BrowserRouter>
      </CookiesProvider>
    </RecoilRoot>
  </React.StrictMode>,
  document.getElementById("root")
);
