import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
    resources: {
      en: {
        translation: {
          default: {
            loading: "Loading...",
            city: "City",
            street: "Street"
          },
          home: {
            title: "Search a room? Search for a room, student housing, studio | Student Rooms.",
            menu: {
              add: "Add student housing",
              profile: "My profile"
            },
            search: {
              title: "What are you looking for?",
              search: "City, School, ...",
              noresults: "No results found"
            }, 
            popularcities: "Popular student cities",
            room: "room",
            rooms: "rooms",
            advert: {
              title: "A room for rent and looking for students?",
              description: "Every day hundreds of students are looking for a room via Student-Rooms. Place your ad and rent out your student room in 1-2-3!"
            },
          },
          listings: {
            title_filtered: "Listings in", 
            title_all: "All listings",
            filter: {
              sortedon: "Sorted on",
              remove: "Clear filters",
            },
            listing: {
              price: "Rent",
              size: "surface",
            },
            advert: {
              title: "Earn back rent money?",
              description: "Invest in student housing and earn back your rent money.",
              button: "discover here",
            },
            nav: {
              "previous": "Vorige",
              "next": "Volgende",
            }
          },
          listing: {
            infobar: {
              price: "rent",
              type: "type",
              size: "surface",
              from: "starting at",
            },
            info: "Info", //facilities/amenities
            location: "Location",
            contact: {
              title: "Practical",
              overview: "Cost overview",
              price: "rent",
              extra: "Extra costs",
              not_included: "Not included",
              contact: "Contact landlord",
              form: {
                first: "i'm interested in the listing at",
                namequestion: "What is your name?",
                name: "Your name",
                emailquestion: "What email can the landlord use to contact you?",
                email: "Jouw e-mail",
                phonequestion: "On what phone can the landlord contact you?",
                phone: "Your phone",
                robot: "Can you confirm you're not a robot?",
                robotproved: "I've proven.",
                contactinfo: "Thank you: here's the landlords contact info",
                error: "An error occured, please try again later.",
                send: "Send"
              }
            },
          },
          footer : {
            disclaimer: "Student-rooms.com is an Upgrade Estate service",
            menu: {
              language: {
                title: "Language",
              },
              joinus: {
                title: "Join us", 
                add: "add room",
                register: "Register"
              },
              help: {
                title: "Help",
                tips: "Tips", 
                FAQ: "FAQ"
              },
              UE: {
                title: "Upgrade Estate", 
                contact: "Contact",
                disclaimer: "Disclaimer"
              }
            },
            terms: "Terms and Conditions",
            privacy: "Privacy"
          },
          notificationbar: {
            warning: "Warning!",
            text: "Do not pay a deposit before you have physically visited the room",
            button: "I understand"
          }
        }
      },
      nl: {
        translation: {
          default: {
            loading: "Laden",
            city: "Stad",
            street: "Straat"
          },
          home: {
            title: "KOT ZOEKEN? Zoek een kot, studentenkamer, studio | Op kot in | Student Rooms.",
            menu: {
              add: "Kot toevoegen",
              profile: "Mijn profiel"
            },
            search: {
              title: "Waar wil je op kot?",
              search: "Stad, school, ...",
              noresults: "Geen resultaten gevonden"
            }, 
            popularcities: "Populaire studentensteden",
            room: "kamer",
            rooms: "kamers",
            advert: {
              title: "Kotbaas? Op zoek naar studenten?",
              description: "Dagelijks zijn er honderden studenten op zoek naar een kot via Student-Rooms. Plaats jouw zoekertje en verhuur je studentenkamer in 1-2-3!"
            },
          },
          listings: {
            title_filtered: "Listings in", 
            title_all: "Alle listings",
            filter: {
              sortedon: "Gesorteerd op",
              remove: "Wis filters",
            },
            listing: {
              price: "huurprijs",
              size: "oppervlakte",
            },
            advert: {
              title: "Huurgeld terugverdienen?",
              description: "Investeer in studentenhuisvesting en verdien je huurgeld terug.",
              button: "Ontdek hier",
            },
            nav: {
              "previous": "vorige",
              "next": "volgende",
            }
          },
          listing: {
            infobar: {
              price: "huurprijs",
              type: "type",
              size: "oppervlakte",
              from: "te huur vanaf",
            },
            info: "Info", //facilities/amenities
            location: "Locatie",
            contact: {
              title: "Praktisch",
              overview: "Kostenoverzicht",
              price: "huurprijs",
              extra: "Extra kosten",
              not_included: "niet inbegrepen",
              contact: "Contacteer kotbaas",
              form: {
                first: "Hallo, ik ben geïnteresseerd in het zoekertje te",
                namequestion: "Jouw naam?",
                name: "Jouw naam",
                emailquestion: "Op welk e-mailadres kan de kotbaas je bereiken?",
                email: "Jouw e-mail",
                phonequestion: "Op welk gsm nummer kan de kotbaas je bereiken?",
                phone: "Jouw telefoonnummer",
                robot: "Laat je ons nog weten dat je geen robot bent?",
                robotproved: "Ik heb het bewezen.",
                contactinfo: "Bedankt. Hier heb je alvast de contactgegevens van de kotbaas:",
                error: "Er is een fout opgetreden. Probeer het later opnieuw.",
                send: "Verzenden"
              }
            }
          },
          footer : {
            disclaimer: "Student-rooms.com is een dienst van Upgrade Estate",
            menu: {
              language: {
                title: "Taal",
              },
              joinus: {
                title: "Doe mee", 
                add: "Kot toevoegen",
                register: "Registreer"
              },
              help: {
                title: "Help",
                tips: "Tips", 
                FAQ: "FAQ"
              },
              UE: {
                title: "Upgrade Estate", 
                contact: "Contact",
                disclaimer: "Disclaimer"
              }
            },
            terms: "Algemene voorwaarden",
            privacy: "Privacy"
          },
          notificationbar: {
            warning: "Let op!",
            text: "Betaal geen voorschot voordat je het kot fysiek hebt bezocht.",
            button: "Ik snap het"
          }
        }
      },
      fr: {
        translation: {
          default: {
            loading: "charger",
            city: "Ville",
            street: "Rue"
          },
          home: {
            title: "VOUS CHERCHEZ UNE CHAMBRE? Trouver une chambre étudiante, studio | Dans une chambre en | Chambres d'étudiants.",
            menu: {
              add: "Ajouter chambre",
              profile: "mon profil"
            },
            search: {
              title: "Où voulez-vous aller ?",
              search: "Ville, école, ...",
              noresults: "Aucun résultat trouvé"
            }, 
            popularcities: "Villes étudiantes populaires",
            room: "kot étudiant",
            rooms: "kots étudiant",
            advert: {
              title: "Propriétaire? à la recherche d'étudiants?",
              description: "Chaque jour, des centaines d’étudiants sont à la recherche d’un kot via Student-Rooms. Placez votre annonce et louer votre chambre d’étudiant dans 1-2-3!"
            },
          },
          listings: {
            title_filtered: "Listings in", 
            title_all: "Tous listings",
            filter: {
              sortedon: "Triés par",
              remove: "Effacer les filtres",
            },
            listing: {
              price: "prix de location",
              size: "surface",
            },
            advert: {
              title: "Récupérer l'argent des loyers ?",
              description: "Investissez dans un logement étudiant et récupérez l'argent de votre loyer.",
              button: "Découvrez ici",
            }, 
            nav: {
              "previous": "Précédent",
              "next": "Suivant",
            }
          },
          listing: {
            infobar: {
              price: "prix de location",
              type: "type",
              size: "surface",
              from: "à louer à partir de",
            },
            info: "Info", //facilities/amenities
            location: "Lieu",
            contact: {
              title: "En pratique",
              overview: "Aperçu des coûts",
              price: "prix de location",
              extra: "Coûts supplémentaires",
              not_included: "non inclus",
              contact: "Contact propriétaire",
              form: {
                first: "je suis intéressé par la liste à",
                namequestion: "Votre nom?",
                name: "Votre nom",
                emailquestion: "A quelle adresse e-mail le propriétaire peut-il vous contacter?",
                email: "Votre e-mail",
                phonequestion: "Sur quel numéro de portable le propriétaire peut-il vous contacter?",
                phone: "Votre numéro de téléphone",
                robot: "Voulez-vous nous faire savoir que vous n'êtes pas un robot?",
                robotproved: "Je l'ai prouvé.",
                contactinfo: "Merci. Ici vous avez les coordonnées du propriétaire :",
                error: "Une erreur est survenue. Réessayez plus tard.",
                send: "Envoyer"
              }
            },
          },
          footer : {
            disclaimer: "Student-rooms.com est un service de Upgrade Estate",
            menu: {
              language: {
                title: "Langue",
              },
              joinus: {
                title: "Rejoignez-nous", 
                add: "Ajouter une chambre",
                register: "Enregistrez"
              },
              help: {
                title: "Aide",
                tips: "Tips", 
                FAQ: "FAQ"
              },
              UE: {
                title: "Upgrade Estate", 
                contact: "Contact",
                disclaimer: "Disclaimer"
              }
            },
            terms: "Conditions générales",
            privacy: "Privacy"
          },
          notificationbar: {
            warning: "Attention!",
            text: "Ne payez pas d'acompte avant d'avoir visité physiquement le dortoir",
            button: "J'ai compris"
          }
        }
      }
    }
  });

export default i18n;