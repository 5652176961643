import { useEffect, useState } from "react";
import { SearchIcon } from "@heroicons/react/solid";
import { Combobox } from "@headlessui/react";
import { useRecoilState } from "recoil";
import { useTranslation } from "react-i18next";

//services
import listingsService from "../services/listings.service";

//import datastores
import { cityState } from "../data/city.atom";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function HomeSearch() {
  const { t } = useTranslation();
  const [query, setQuery] = useState("");
  const [results, setResults] = useState();
  const [cities, setCities] = useRecoilState(cityState);

  const filteredCities =
    query === ""
      ? []
      : cities.filter((city) => {
          return city.name.toLowerCase().includes(query.toLowerCase());
        });

  useEffect(() => {
    listingsService
      .getSearch(query)
      .then((response) => {
        setResults(response.data);
      })
      .catch((error) => {});
  }, [query]);

  return (
    <div
      className="absolute w-full text-center px-10"
      style={{ "margin-top": "-32rem" }}
    >
      <h1 className="text-white text-4xl md:text-5xl pb-10 font-medium ">
        {t("home.search.title")}
      </h1>
      <Combobox
        as="div"
        className="max-w-xl mx-auto transform divide-y divide-gray-100 overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all"
        onChange={(result) =>
          result.nid
            ? (window.location =
                result.type +
                "/" +
                result.city +
                "/" +
                result.address +
                "/" +
                result.nid)
            : (window.location = result.name)
        }
      >
        <div className="relative">
          <SearchIcon
            className="pointer-events-none absolute top-3.5 left-4 h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
          <Combobox.Input
            className="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-gray-800 placeholder-gray-400 focus:ring-0 sm:text-sm"
            placeholder={t("home.search.search")}
            onChange={(event) => {
              if (event.target.value.length > 2) {
                setQuery(event.target.value);
              }
            }}
          />
        </div>

        {query !== "" && results.length > 0 && (
          <Combobox.Options
            static
            className="max-h-72 scroll-py-2 overflow-y-auto py-2 text-sm text-gray-800 capitalize text-left"
          >
            {filteredCities.map((city) => (
              <Combobox.Option
                key={city["name"]}
                value={city}
                className={({ active }) =>
                  classNames(
                    "cursor-default select-none px-4 py-2",
                    active && "bg-indigo-600 text-white"
                  )
                }
              >
                🌎 <span className="font-medium ml-1">{city["name"]}</span>
              </Combobox.Option>
            ))}
            {results.map((result) => (
              <Combobox.Option
                key={result["nid"]}
                value={result}
                className={({ active }) =>
                  classNames(
                    "cursor-default select-none px-4 py-2",
                    active && "bg-indigo-600 text-white"
                  )
                }
              >
                📍 <span className="font-medium">{result["city"]}</span> -{" "}
                {result["title"]}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        )}

        {query !== "" && results.length === 0 && (
          <p className="p-4 text-sm text-gray-500">
            {t("home.search.noresults")}...
          </p>
        )}
      </Combobox>
    </div>
  );
}
