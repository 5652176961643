//import packages
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';

//import components
import SecondaryMenu from "../../components/SecondaryMenu.component";
import Footer from "../../components/Footer.component";

export default function Terms() {
  const { t, i18n } = useTranslation();
  const [lang, setLang] = useState();

  useEffect(() => {
    document.title = "Terms and Conditions"
    setLang(i18n.language);
  }, [i18n.language]);

  return (
    <div className="bg-grey-100">
      <SecondaryMenu />
      <div className="max-w-6xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 mb-20">
        {lang == "nl" ?
          <div className="font-extralight">
            <h1 className="text-5xl font-semibold pb-5">Algemene voorwaarden</h1>
            <p>
            De website <a href="https://www.student-rooms.com" className="text-indigo-600">www.student-rooms.com</a> is ontwikkeld en wordt beheerd door Upgrade Estate NV:<br/>
            Upgrade Estate NV<br/>
            Burggravenlaan 31, bus 1<br/>
            9000 Gent<br/>
            België<br/>
            T 09 233 76 66<br/>
            <br/>
            <a href="mailto:info@upgrade-estate.be" className="text-indigo-600">info@upgrade-estate.be</a><br/>
            RPR GENT KBO 0840.066.124<br/>
            BTW BE 0840.066.124<br/>
            Onderstaande voorwaarden en bepalingen zijn van toepassing op het gebruik van deze website van Upgrade Estate NV, met de domeinnaam www.upgrade-estate.be en alle andere websites die door Upgrade Estate NV worden ontwikkeld (op deze pagina genaamd ‘de website’).<br/>
            De bezoeker aanvaardt, door het gebruik van de website, zonder enige uitzondering de gebruiksvoorwaarden en verbindt er zich toe deze na te leven.<br/>
            Upgrade Estate heeft het recht om de bepalingen van deze algemene voorwaarden aan te passen, waarbij de gebruiker wordt verzocht om de voorwaarden na te lezen na elk gebruik van de website.<br/>
            </p>
            <h2 className="text-lg font-semibold mt-5 mb-3">AANSPRAKELIJKHEID</h2>
            <p>
            De website dient om algemene informatie te verstrekken over de projecten en activiteiten van Upgrade Estate NV en haar commerciële entiteiten. De gebruiker verwerkt de informatie op eigen risico.
            <br/><br/>
            De informatie en de gegevens die vermeld staan op deze website werden met de grootste zorg samengesteld. Toch kunnen Upgrade Estate NV, haar dochtervennootschappen en/of informatieleveranciers niet garanderen dat de volledigheid, correctheid of geschiktheid van de informatie niet garanderen voor om het even welke doeleinden. Upgrade Estate is niet aansprakelijk voor de onjuistheid of onvolledigheid van de informatie die op deze website wordt weergegeven en kan eveneens niet aansprakelijk gesteld worden voor storingen of onderbrekingen. Zelfs indien deze zou voortvloeien uit herhaaldelijke of zware fout veroorzaakt door de website, door hacking, virussen, computermisdrijven. Ook kan Upgrade Estate NV niet worden aansprakelijk gesteld bij eventuele disfuncties van het contactsysteem.
            <br/><br/>
            Indien de gebruiker incorrecte informatie zou opmerken, kan hij contact opnemen met de webbeheerder via info@upgrade-estate.be<br/>
            </p>
            <h2 className="text-lg font-semibold mt-5 mb-3">INTELLECTUELE RECHTEN</h2>
            <p>
            De website en de inhoud ervan (teksten, lay-out, structuur, grafische vormgeving, logo, product- en bedrijfsnamen, software, etc.) worden beschermd door de intellectuele rechten van Upgrade Estate NV., leveranciers en partners. Deze intellectuele rechten betreffen onder meer, maar niet limitatief: het auteursrecht, de naburige rechten, de databankrechten en de merkenrechten.
            <br/><br/>
            Product- of bedrijfsnamen die niet tot Upgrade Estate NV behoren, maar wel vermeld zijn op deze website, kunnen merken zijn van respectieve eigenaars.
            <br/><br/>
            De gebruiker van de website mag geen informatie, project, dienst, product of software aanpassen, kopiëren, reproduceren, doorgeven, verspreiden, publiceren, verkopen, werken creëren die zijn afgeleide zijn zonder voorafgaande schriftelijke toestemming van Upgrade Estate NV of de rechthebbende.
            </p>
            <h2 className="text-lg font-semibold mt-5 mb-3">LINKS</h2>
            <p>
            De website kan links bevatten naar andere websites die mogelijks van belang zijn voor de gebruiker. Deze hyperlinks zijn louter van informatieve aard. Upgrade Estate NV kan niet aansprakelijk worden gesteld voor de inhoud of het gebruik ervan.
            </p>
            <h2 className="text-lg font-semibold mt-5 mb-3">TOEPASSELIJK RECHT EN BEVOEGDHEID</h2>
            <p>
            Alle geschillen die ontstaan uit de website of het gebruik ervan zullen enkel onderworpen zijn aan het Belgisch recht, waarbij de rechtbanken van het arrondissement Gent bevoegd zijn. Enkel voor deze rechtbanken kan Upgrade Estate NV worden gedagvaard.
            </p>
            <h2 className="text-lg font-semibold mt-5 mb-3">BESCHERMING VAN DE PERSOONLIJKE LEVENSSFEER</h2>
            <p>
            Upgrade Estate NV hecht belang aan en draagt zorg voor de privacy van elke gebruiker van de website. Het grootste deel van de informatie op de website is beschikbaar zonder dat er persoonlijke informatie moeten worden verstrekt. In sommige gevallen kan het zijn dat de gebruiker om persoonlijke gegevens wordt gevraagd. Deze informatie zal louter gebruikt worden om informatie te verstrekken. Door uw persoonsgegevens aan Upgrade Estate NV te verstrekken, geeft u Upgrade Estate NV uitdrukkelijk de toestemming om uw gegevens te verwerken voor onderstaande doeleinden.
            <br/><br/>
            Upgrade Estate NV verzamelt de persoonsgegevens van gebruikers die hun gegevens nalaten door het invullen van een van de contactformulieren of via een emailbericht. Upgrade Estate NV zal deze gegevens niet verspreiden aan derden, tenzij dit verplicht wordt door de wet of wanneer politiediensten of andere gerechtelijke instanties ons hierom verzoeken. De persoonsgegevens zijn vertrouwelijk en worden enkel door de interne diensten van Upgrade Estate NV worden gebruikt met als doel: verpersoonlijken of versterken van communicatie, met name via informatieve emails, updates, brieven en om u op de hoogte te houden van onze producten en dienstverlening en om u de gevraagde informatie te bezorgen, indien u hiervoor gekozen heeft. Upgrade Estate NV gebruikt uw gegevens ook voor eigen marketingdoeleinden, zowel online als offline.
            <br/><br/>
            Upgrade Estate NV verbindt er zich toe om uw persoonsgegevens niet te verkopen, commercialiseren en verhuren aan derden. Upgrade Estate NV zal de betrokken gebruikers verwittigen in geval van overdracht of gebruik van persoonsgegevens aan derden, zodat deze de mogelijkheid hebben om dit toe te laten of bezwaarrecht uit te oefenen. Upgrade Estate NV kan geconsolideerde statistieken betreffende klanten, aanbrenger en leads leveren aan vertrouwelijke derden, maar deze statistieken zullen geen persoonsgegevens bevatten. Huidig artikel kan wel de overgang of overdracht van activiteiten aan derden beletten.
            <br/><br/>
            U kan zich op verzoek steeds verzetten tegen het gebruik van uw gegevens voor directe marketing van Upgrade Estate NV. Ook kan de gebruiker zijn of haar toegangsrecht tot het bestand en zijn of haar verbeteringsrecht van de aangaande inlichtingen uitoefenen door contact op te nemen met Upgrade Estate NV conform de Europese en nationale regelgeving. Indien u een van bovenstaande zaken wil melden, kan u zich richten naar privacy@upgrade-estate.be of Upgrade Estate NV, Burggravenlaan 31 (bus 1), 9000 Gent.
            <br/><br/>
            Upgrade Estate NV verzamelt de volgende anonieme ofwel niet-persoonlijke gegevens van de gebruiker: IP-adres, browser type, besturingsprogramma of domeinnaam van de website langs welke de gebruiker heeft doorgeklikt. Daardoor kan Upgrade Estate NV de website constant optimaliseren.              
            </p>
            <h2 className="text-lg font-semibold mt-5 mb-3">AANSPRAKELIJKHEID</h2>
            <p>
            Conform de wet Verwerking Persoonsgegevens beschikt de gebruiker over een wettelijk recht op inzage en eventuele correctie van zijn of haar persoonsgegevens. Door middel van bewijs van identiteit (een kopie van uw identiteitskaart) kunt u via een gedateerde schriftelijke en ondertekende aanvraag aan Upgrade Estate NV, Burggravenlaan 31 (bus 1) of via <a href="mailto:privacy@upgrade-estate.be" target="_blank" className="text-indigo-600">privacy@upgrade-estate.be</a>, kosteloos een schriftelijke mededeling bekomen van uw persoonlijke gegevens. Indien deze onjuist, niet volledig of niet permanent zijn, kunt u deze laten corrigeren.
            </p>
          </div>
        : lang == "fr" ? 
        <div className="font-extralight">
          <h1 className="text-5xl font-semibold pb-5">CONDITIONS GÉNÉRALES</h1>
          <p>
          Le site web www.student-rooms.com a été développé et est géré par Upgrade Estate NV/SA :
          <br/><br/>
          Upgrade Estate NV/SA<br/>
          Burggravenlaan 31, bus 1<br/>
          9000 Gent/Gand<br/>
          Belgique<br/>
          T 09 233 76 66<br/>
          F 09 233 76 65<br/>
          <br/>
          info@upgrade-estate.be<br/>
          RPM GAND BCE 0840.066.124<br/>
          TVA BE 0840.066.124<br/>
          Les conditions et dispositions énoncées ci-après s’appliquent à l’utilisation de ce site web d’Upgrade Estate NV/SA, ayant pour nom de domaine www.upgrade-estate.be, ainsi que de tous les autres sites web développés par Upgrade Estate NV/SA (dénommés sur cette page « le site web »).
          <br/><br/>
          En utilisant le site web, le visiteur accepte toutes les conditions d’utilisation sans exception et s’engage à les respecter.
          <br/><br/>
          Upgrade Estate a le droit de modifier les dispositions des présentes conditions générales et invite dès lors l’utilisateur à les consulter après chaque utilisation du site web.
          </p>
          <h2 className="text-lg font-semibold mt-5 mb-3">RESPONSABILITÉ</h2>
          <p>
          Le site web sert à fournir des informations générales sur les projets et activités d’Upgrade Estate NV/SA et de ses entités commerciales. L’utilisateur traitera ces informations à ses propres risques et périls.
          <br/><br/>
          Même si les informations et données figurant sur ce site web ont été rédigées avec le plus grand soin, Upgrade Estate NV/SA, ses filiales et/ou ses fournisseurs d’informations ne peuvent en garantir le caractère complet, l’exactitude ou la pertinence à quelque fin que ce soit. Upgrade Estate ne pourra être tenue responsable de l’inexactitude ou du caractère incomplet des informations figurant sur ce site web, ni des éventuelles interruptions ou défaillances – même si celles-ci devaient découler d’erreurs graves ou répétées provoquées par le site web pour cause de piratage, de virus ou d’abus informatiques. Upgrade Estate NV/SA ne pourra pas davantage être tenue responsable des éventuels dysfonctionnements du système de contact.
          <br/><br/>
          L’utilisateur constatant des informations incorrectes pourra prendre contact avec le gestionnaire du site en adressant un e-mail à info@upgrade-estate.be.
          </p>
          <h2 className="text-lg font-semibold mt-5 mb-3">DROITS INTELLECTUELS</h2>
          <p>
          Le site web et son contenu (textes, mise en page, structure, graphisme, logo, noms de produits et d’entreprises, logiciels, etc.) sont protégés par les droits intellectuels d’Upgrade Estate NV/SA, de ses fournisseurs et de ses partenaires. Ces droits intellectuels concernent notamment, sans toutefois s’y limiter : les droits d’auteur, les droits voisins, les droits sur les bases de données et les droits de marques.
          <br/><br/>
          Les noms de produits ou d’entreprises qui n’appartiennent pas à Upgrade Estate NV/SA mais sont mentionnés sur ce site web peuvent être des marques de propriétaires respectifs.
          <br/><br/>
          L’utilisateur du site web ne peut modifier, copier, reproduire, transférer, diffuser, publier, vendre ou créer des informations, projets, services, produits, logiciels ou travaux dérivés sans l’accord écrit préalable d’Upgrade Estate NV/SA ou de son ayant droit.
          </p>
          <h2 className="text-lg font-semibold mt-5 mb-3">LIENS</h2>
          <p>
          Le site web peut contenir des liens renvoyant vers d’autres sites web potentiellement utiles à l’utilisateur. Ces hyperliens sont de nature purement informative. Upgrade Estate NV/SA ne pourra être tenue responsable de leur contenu ou utilisation.
          </p>
          <h2 className="text-lg font-semibold mt-5 mb-3">DROIT APPLICABLE ET COMPÉTENCES</h2>
          <p>
          Tout litige découlant du site web ou de son utilisation sera régi exclusivement par le droit belge et soumis à la compétence des tribunaux de l’arrondissement de Gand. Upgrade Estate NV/SA pourra uniquement être citée devant ces tribunaux.
          </p>
          <h2 className="text-lg font-semibold mt-5 mb-3">PROTECTION DE LA VIE PRIVÉE</h2>
          <p>
          Upgrade Estate NV/SA accorde de l’importance et veille à la protection de la vie privée de chaque utilisateur du site web. La majeure partie des informations présentes sur le site sont accessibles sans qu’il soit nécessaire de fournir des informations personnelles. Dans certains cas, il est possible que l’utilisateur soit invité à fournir des données personnelles. Celles-ci seront alors utilisées uniquement en vue de fournir des informations. En partageant vos données personnelles avec Upgrade Estate NV/SA, vous autorisez expressément Upgrade Estate NV/SA à les traiter aux fins citées ci-après.
          <br/>
          Upgrade Estate NV/SA collecte les données personnelles des utilisateurs qui les fournissent par le biais d’un des formulaires de contact ou par e-mail. Upgrade Estate NV/SA ne communiquera pas ces données à des tiers, sauf obligation légale ou requête des services de police ou autres instances judiciaires en ce sens. Les données personnelles sont confidentielles et seront uniquement utilisées par les services internes d’Upgrade Estate NV/SA aux fins suivantes : personnalisation ou renforcement des communications, notamment par le biais d’e-mails informatifs, de mises à jour, de courriers, ou encore pour vous tenir informé de nos produits et services et vous transmettre les informations demandées, le cas échéant. Upgrade Estate NV/SA utilisera également vos données à des fins de marketing propre, en ligne comme hors ligne.
          <br/><br/>
          Upgrade Estate NV/SA s’engage à ne pas vendre, commercialiser ou louer vos informations personnelles à des tiers. Upgrade Estate NV/SA avertira les utilisateurs concernés en cas de transfert ou d’utilisation de leurs données personnelles par des tiers, de manière à leur permettre d’autoriser ces activités ou d’exercer au contraire leur droit d’opposition. Upgrade Estate NV/SA peut fournir des statistiques consolidées au sujet de clients, d’informateurs et de leads à des tiers de confiance, mais ces statistiques ne peuvent pas contenir de données personnelles. Le présent article peut cependant empêcher le transfert ou la cession d’activités à des tiers.
          <br/><br/>
          Vous avez toujours la possibilité d’introduire une demande en vue de vous opposer à l’utilisation de vos données à des fins de marketing direct par Upgrade Estate NV/SA. L’utilisateur peut également exercer son droit d’accès au dossier et son droit de rectification des données le concernant en prenant contact avec Upgrade Estate NV/SA conformément à la réglementation nationale et européenne. Pour entreprendre une des actions précitées, vous pouvez écrire à privacy@upgrade-estate.be ou Upgrade Estate NV/SA, Burggravenlaan 31 (bus 1), 9000 Gent/Gand.
          <br/><br/>
          Upgrade Estate NV/SA collecte les données anonymes ou non personnelles suivantes de l’utilisateur : adresse IP, type de navigateur, système d’exploitation ou nom de domaine du site web par lequel l’utilisateur a accédé au site web. La collecte de ces données permet à Upgrade Estate NV/SA d’optimiser continuellement son site web.  
          </p>
          <h2 className="text-lg font-semibold mt-5 mb-3">DROIT D’ACCÈS</h2>
          <p>
          Conformément à la loi relative à la protection de la vie privée à l’égard des traitements de données à caractère personnel, l’utilisateur dispose d’un droit légal d’accès et de rectification éventuelle de ses données personnelles. Preuve de votre identité à l’appui (copie de votre carte d’identité), vous pouvez envoyer une demande écrite, datée et signée, à Upgrade Estate NV/SA, Burggravenlaan 31 (bus 1) à 9000 Gent/Gand ou par e-mail à privacy@upgrade-estate.be, pour obtenir gratuitement une copie écrite de vos données personnelles. Si celles-ci s’avèrent incorrectes, incomplètes ou non permanentes, vous pouvez demander leur rectification.
          </p>
        </div>
        :
        <div className="font-extralight">
          <h1 className="text-5xl font-semibold pb-5">General terms and conditions</h1>
          <p>
          The website www.student-rooms.com has been developed and is managed by Upgrade Estate NV:<br/>
          <br/>
          Upgrade Estate NV <br/>
          Burggravenlaan 31, bus 1 <br/>
          9000 Ghent <br/>
          Belgium <br/>
          T 09 233 76 66 <br/>
          F 09 233 76 65<br/>

          info@upgrade-estate.be <br/>
          RPR GHENT KBO 0840.066.124 <br/>
          VAT BE 0840.066.124 <br/>
          The following terms and conditions apply to the use of this website of Upgrade Estate NV, with the domain name www.upgrade estate.be and all other websites developed by Upgrade Estate NV (termed the ‘website’ on this page).
          <br/><br/>
          By using the website, the user accepts without any exception the terms of use and undertakes to comply with them.
          <br/><br/>
          Upgrade Estate is entitled to amend the provisions of these general terms and conditions, whereby users are asked to read the terms and conditions every time they use the website.
          </p>
          <h2 className="text-lg font-semibold mt-5 mb-3">LIABILITY</h2>
          <p>
          The website is intended to provide general information on the projects and activities of Upgrade Estate NV and its commercial entities. The user processes the information at his own risk.
          <br/><br/>
          The information and data mentioned on this website have been compiled with the greatest care. However, Upgrade Estate NV, its subsidiaries and/or information providers cannot guarantee the completeness, accuracy, or suitability of the information for any purposes. Upgrade Estate is not responsible for the inaccuracy or incompleteness of the information displayed on this website nor can it be held liable for malfunctions or interruptions, even if these arise from a repeated or serious error caused by the website, by hacking, viruses, or computer crime. Nor can Upgrade Estate NV be held liable for any malfunctions in the contact system.
          <br/><br/>
          If the user notices incorrect information, he can contact the webmaster at info@upgrade-estate.be
          </p>
          <h2 className="text-lg font-semibold mt-5 mb-3">INTELLECTUAL PROPERTY RIGHTS</h2>
          <p>
          The website and its content (text, layout, structure, graphic design, logo, product and company names, software, etc.) are protected by the intellectual property rights of Upgrade Estate NV and its suppliers and partners. These intellectual property rights include but are not limited to the copyright, related rights, database rights and trademark rights.
          <br/><br/>
          Product or company names that are not owned by Upgrade Estate NV, but which are listed on this website, may be trademarks of their respective owners.
          <br/><br/>
          The user of the website must not modify, copy, reproduce, transmit, distribute, publish, or sell any information, project, service, product, or software or create works derived from these without the prior written consent of Upgrade Estate NV or the copyright holder.
          </p>
          <h2 className="text-lg font-semibold mt-5 mb-3">LINKS</h2>
          <p>
          The website may include links to other websites that may be of interest to the user. These hyperlinks are purely for information purposes. Upgrade Estate NV cannot be held liable for the content or use thereof.
          </p>
          <h2 className="text-lg font-semibold mt-5 mb-3">APPLICABLE LAW AND JURISDICTION</h2>
          <p>
          Any disputes arising from the website or its use shall only be governed by Belgian law, whereby the courts of the legal district of Ghent shall have jurisdiction. Upgrade Estate NV can only be summoned to appear before these courts.
          </p>
          <h2 className="text-lg font-semibold mt-5 mb-3">PROTECTION OF PRIVACY</h2>
          <p>
          Upgrade Estate NV attaches importance to and ensures the privacy of every user of the website. Most of the information on the website is available without the need to provide any personal information. In some cases, the user may be asked for personal information. This information will only be used to provide information. By providing your personal data to Upgrade Estate NV, you expressly consent to Upgrade Estate NV processing your data for the purposes set out below.
          <br/><br/>
          Upgrade Estate NV collects personal data from users who leave their data by completing one of the contact forms or by sending an e-mail. Upgrade Estate NV will not disclose these data to third parties, unless required by law or if the police or other judicial authorities ask us to do so. The personal data are confidential and will only be used by the internal services of Upgrade Estate NV with the aim of personalising or reinforcing communication, in particular via informative e-mails, updates, letters, to keep you informed about our products and services, and to provide you with the requested information, if you have chosen this. Upgrade Estate NV uses your data for its own marketing purposes, both online and offline.
          <br/><br/>
          Upgrade Estate NV undertakes not to sell, market, or rent your personal data to third parties. Upgrade Estate NV will notify data subjects if personal data are transferred to or used by third parties so that they have the opportunity to allow this to happen or to exercise their right of objection. Upgrade Estate NV may provide consolidated customer, provider and lead statistics to confidential third parties, but these statistics will not contain any personal data. However, this article may prevent the transmission or transfer of activities to third parties.
          <br/><br/>
          Upon request, you may always oppose the use of your data for direct marketing purposes by Upgrade Estate NV. Also, the user may exercise his right of access to the file and his right to exercise his right to correct information by contacting Upgrade Estate NV in accordance with European and national legislation. If you wish to report one of the above, please contact privacy@upgrade-estate.be or Upgrade Estate NV, Burggravenlaan 31 (bus 1), 9000 Ghent.
          <br/><br/>
          Upgrade Estate NV collects the following anonymous or non-personal data of the user: IP address, browser type, operating system or domain name of the website through which the user has clicked. This allows Upgrade Estate NV to optimise the website regularly.
          </p>
          <h2 className="text-lg font-semibold mt-5 mb-3">RIGHT OF INSPECTION</h2>
          <p>
          In accordance with the Data Processing act, the user has a statutory right to inspect and, if necessary, correct his personal data. By means of proof of identity (a copy of your identity card), you can obtain a free written notification of your personal data by sending a dated and signed request to Upgrade Estate NV, Burggravenlaan 31 (bus 1), 9000 Ghent or privacy@upgrade-estate.be. If your personal data are incorrect, incomplete or not permanent, you can have them corrected.
          </p>
        </div>
        }
      </div>          
      <Footer />
    </div>
  );
}
