import { useTranslation } from 'react-i18next';

export default function Loading() {
  const { t } = useTranslation();

  return (
    <div className="m-5">
      <h2 className="text-4xl pt-10 pb-2 font-medium">{t("default.loading")}...</h2>
    </div>
  );
}
