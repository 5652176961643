//import packages
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import GoogleMapReact from "google-map-react";
import { useTranslation } from "react-i18next";
import Pagination from "react-responsive-pagination";

//import components
import MiniMenu from "../components/MiniMenu.component";
import FilterBar from "../components/Filterbar.component";
import Listing from "../components/Listing.component";
import Empty from "../components/Empty.component";
import Loading from "../components/Loading.component";

//import services
import ListingService from "../services/listings.service";

export default function Listings() {
  const { t } = useTranslation();

  const MarkerComponent = ({ listing }) => (
    <Link
      to={
        listing.type &&
        listing.city &&
        listing.street &&
        listing.number &&
        listing.nid
          ? "/" +
            listing.type +
            "/" +
            listing.city +
            "/" +
            listing.street +
            "_" +
            listing.number +
            "/" +
            listing.nid
          : "/404/"
      }
      target="_blank"
    >
      <div className="bg-indigo-600 text-white w-10 h-auto text-center py-1 rounded-sm font-medium">
        € {parseInt(listing.price)}
      </div>
    </Link>
  );

  const [listings, setListings] = useState();
  const [filter, setFilter] = useState([]);
  const [pages, setPages] = useState();
  const [pageParam, setPageParam] = useState(1);

  let { path, city, street } = useParams();

  //filterbar states
  const [priceFilter, setPriceFilter] = useState([50, 2000]);
  const [typeFilter, setTypeFilter] = useState([]);
  const [sortFilter, setSortFilter] = useState([]);

  useEffect(() => {
    const filter = {};
    filter["min-price"] = priceFilter[0];
    filter["max-price"] = priceFilter[1];
    filter["street"] = street;
    if (path && path != "listings") {
      if (["Studio", "Flat", "Room", "House"].indexOf(path) > -1) {
        filter["type"] = path;
      } else {
        filter["city"] = path;
      }
    }
    if (city) {
      filter["city"] = city;
    }
    setFilter(filter);
  }, [priceFilter, typeFilter]);

  useEffect(() => {
    if (filter && pageParam) {
      if (filter["type"]) {
        setTypeFilter(filter["type"]);
      }
      ListingService.getPreviews(filter, pageParam - 1)
        .then((response) => {
          setPages(response.data.slice(-1)[0]["number_of_pages"]);
          response.data.pop(1);
          if (sortFilter) {
            if (sortFilter["id"] == 1) {
              response.data.sort((a, b) => a.price.localeCompare(b.price));
            }
            if (sortFilter["id"] == 2) {
              response.data.sort((a, b) => b.price.localeCompare(a.price));
            }
            if (sortFilter["id"] == 3) {
              response.data.sort((a, b) => a.title.localeCompare(b.title));
            }
          }
          document.title = filter["city"]
            ? t("listings.title_filtered") +
              " " +
              filter["city"].charAt(0).toUpperCase() +
              filter["city"].slice(1)
            : t("listings.title_all");
          setListings(response.data);
        })
        .catch((error) => {});
    }
  }, [filter, sortFilter, pageParam]);

  function handlePageChange(page) {
    setPageParam(page);
  }

  return (
    <>
      <MiniMenu data="search" />
      <FilterBar
        city={filter["city"]}
        setPriceFilter={setPriceFilter}
        typeFilter={typeFilter}
        setTypeFilter={setTypeFilter}
        sortFilter={sortFilter}
        setSortFilter={setSortFilter}
        street={street}
      />

      <div className="flex h-screen bg-gray-50">
        <div className="w-full max-h-screen flex-col overflow-auto">
          {!listings ? (
            <Loading />
          ) : listings.length === 0 ? (
            <Empty />
          ) : (
            <>
              <ul
                role="list"
                className="grid grid-cols-1 gap-8 md:grid-cols-2 xl:grid-cols-3 m-5"
              >
                {listings.map((listing, i) =>
                  i == 5 ? (
                    <>
                      <Listing entity={listing} advert={true} />
                      <Listing entity={listing} />
                    </>
                  ) : (
                    <Listing entity={listing} />
                  )
                )}
              </ul>
            </>
          )}
          <div className="mx-auto w-max">
            <Pagination
              total={pages}
              current={pageParam}
              onPageChange={(page) => handlePageChange(page)}
              className="pagination"
              pageItemClassName="navitem"
              activeItemClassName="navitem-active"
              disabledItemClassName="navitem-disabled"
              previousLabel={t("listings.nav.previous")}
              nextLabel={t("listings.nav.next")}
            />
          </div>
        </div>
        <div className="w-full flex-col flex-grow hidden md:block">
          <GoogleMapReact
            bootstrapURLKeys={{
              key: "AIzaSyDbpR_xXxZ8kPr31rz9pGHx9Z59aEiTRGg",
            }}
            defaultCenter={{
              lat: 50.5286224,
              lng: 4.4780462,
            }}
            defaultZoom={8}
          >
            {listings ? (
              listings.map((listing) => (
                <MarkerComponent
                  lat={listing.latitude}
                  lng={listing.longitude}
                  listing={listing}
                />
              ))
            ) : (
              <></>
            )}
          </GoogleMapReact>
        </div>
      </div>
    </>
  );
}
