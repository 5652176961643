export default function Empty() {
  return (
    <div className="m-5">
      <h2 className="text-4xl pt-10 pb-2 font-medium">
        Helaas, geen resultaten.
      </h2>
      <a
        href="/listings/"
        className="text-sm font-medium mr-3 font-medium text-indigo-500 hover:text-indigo-800"
      >
        Ga naar alle resultaten.
      </a>
    </div>
  );
}
