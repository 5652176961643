import { Fragment, useState } from "react";
import { Dialog, Menu, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { ChevronDownIcon } from "@heroicons/react/solid";
import { useTranslation } from 'react-i18next';


//range slider
import Slider from "rc-slider";
import "rc-slider/assets/index.css";

const typeOptions = ["Studio", "Flat", "Room", "House"];

const sortOptions = [
  { name: "Prijs laag naar hoog", id: 1 },
  { name: "Prijs hoog naar laag", id: 2 },
  { name: "Laatst toegevoegd", id: 3 },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function FilterBar({
  setPriceFilter,
  typeFilter,
  setTypeFilter,
  sortFilter,
  setSortFilter,
  city,
  street,
}) {

  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const [livePriceFilter, setLivePriceFilter] = useState([50, 2000]);

  return (
    <div className="bg-gray-50 flex-none">
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 flex z-40 sm:hidden"
          onClose={setOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="translate-x-full"
          >
            <div className="ml-auto relative max-w-xs w-full h-full bg-white shadow-xl py-4 pb-6 flex flex-col overflow-y-auto">
              <div className="px-4 flex items-center justify-between">
                <h2 className="text-lg font-medium text-gray-900">Filters</h2>
                <button
                  type="button"
                  className="-mr-2 w-10 h-10 bg-white p-2 rounded-md flex items-center justify-center text-gray-400 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                  onClick={() => setOpen(false)}
                >
                  <span className="sr-only">Close menu</span>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
            </div>
          </Transition.Child>
        </Dialog>
      </Transition.Root>

      <div className="">
        <section aria-labelledby="filter-heading" className="py-3 border-b">
          <div className="flex flex-wrap items-center">
            {/*
              type
            */}
            <div className="flex flex-wrap items-center lg:w-1/2 w-full">
              <Menu as="div" className="relative z-5 inline-block text-left w-full md:w-auto md:text-left text-center">
                <div>
                  <Menu.Button className="group inline-flex justify-center text-sm font-medium text-gray-700 hover:text-gray-900 md:pl-8">
                    {typeFilter.length > 0 ? typeFilter : <>Type</>}
                    <ChevronDownIcon
                      className="flex-shrink-0 -mr-1 ml-1 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                      aria-hidden="true"
                    />
                  </Menu.Button>
                </div>

                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="origin-top-left absolute left-0 z-10 mt-2 w-full lg:w-40 lg:ml-8 text-left rounded-md shadow-2xl bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1">
                      {typeOptions.map((option) => (
                        <Menu.Item key={option}>
                          {({ active }) => (
                            <a
                              onClick={() =>
                                city
                                  ? (window.location = "/" + option + "/" + city)
                                  : (window.location = "/" + option + "/")
                              }
                              className="block px-4 py-2 text-sm font-medium text-gray-900"
                            >
                              {option}
                            </a>
                          )}
                        </Menu.Item>
                      ))}
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>

              {/*
                sort
              */}
              <Menu as="div" className="relative z-5 inline-block text-left w-full md:w-1/4 md:text-left text-center">
                <Menu.Button className="group inline-flex justify-center text-sm font-medium text-gray-700 hover:text-gray-900 md:pl-8">
                  {sortFilter["name"] ? sortFilter["name"] : <>{t("listings.filter.sortedon")}</>}
                  <ChevronDownIcon
                    className="flex-shrink-0 -mr-1 ml-1 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                    aria-hidden="true"
                  />
                </Menu.Button>

                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="origin-top-left absolute left-0 z-10 mt-2 w-full lg:w-40 lg:ml-8 text-left rounded-md shadow-2xl bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1">
                      {sortOptions.map((option) => (
                        <Menu.Item key={option}>
                          {({ active }) => (
                            <a
                              onClick={() => setSortFilter(option)}
                              className="block px-4 py-2 text-sm font-medium text-gray-900"
                            >
                              {option.name}
                            </a>
                          )}
                        </Menu.Item>
                      ))}
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>

              <div className="w-full md:w-2/4 flex md:mx-0 mx-auto">
                <p className="text-sm font-medium text-gray-700 px-3 whitespace-nowrap">
                  Min: €{livePriceFilter[0]}
                </p>
                <Slider
                  range
                  pushable={1}
                  defaultValue={[50, 2000]}
                  step={50}
                  min={50}
                  max={2000}
                  onChange={setLivePriceFilter}
                  onAfterChange={setPriceFilter}
                  className="mt-1"
                />
                <p className="text-sm font-medium text-gray-700 px-5 whitespace-nowrap">
                  Max: €{livePriceFilter[1]}
                </p>
              </div>
            </div>

            <div className="flex flex-wrap items-center lg:w-1/2 w-full">
              {city && city != "listings" ? (
                <div className="w-full md:w-1/4 md:text-left text-center">
                  <p className="text-sm font-medium text-gray-700 px-5 whitespace-nowrap capitalize">
                  {t('default.city')}: <span className="font-bold">{city}</span>
                  </p>
                </div>
              ) : (
                <></>
              )}

              {street ? (
                <div className="w-full md:w-1/4 md:text-left text-center">
                  <p className="text-sm font-medium text-gray-700 px-5 whitespace-nowrap capitalize">
                  {t('default.street')}: <span className="font-bold">{street}</span>
                  </p>
                </div>
              ) : (
                <></>
              )}

              <div className="w-full md:w-2/4 md:text-right text-center">
                <a
                  onClick={() => window.location.replace("/listings/")}
                  className="text-sm font-medium mr-3 font-medium text-indigo-500 hover:text-indigo-800"
                >
                  {t('listings.filter.remove')}
                </a>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
