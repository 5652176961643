import { ExclamationIcon } from '@heroicons/react/outline'
import { useCookies } from 'react-cookie';
import { useState } from 'react'
import { useTranslation } from 'react-i18next';


export default function NotificationBar() {
    const { t } = useTranslation();

    const [alertCookie, setAlertCookie] = useCookies(['alert']);
    const [hide, setHide] = useState(false);

    function checkCookie() {
        setAlertCookie('check', "true", { path: '/', maxAge: "86400" });
        setHide(true)
    }

    return (
        <div className={hide ? "invisible": "visible"}>
            <div className="fixed bottom-0 inset-x-0 pb-2 sm:pb-5 z-50">
                <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
                    <div className="p-2 rounded-lg bg-indigo-600 shadow-lg sm:p-3">
                        <div className="flex items-center justify-between flex-wrap">
                            <div className="w-0 flex-1 flex items-center">
                            <span className="flex p-2 rounded-lg bg-indigo-800">
                                <ExclamationIcon className="h-6 w-6 text-white" aria-hidden="true" />
                            </span>
                            <p className="ml-3 font-medium text-white">
                                <span className="inline"><b>{t('notificationbar.warning')}</b> {t('notificationbar.text')}</span>
                            </p>
                            </div>
                            <div className="order-2 flex-shrink-0 sm:order-3 sm:ml-2">
                            <span onClick={checkCookie} className="flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-indigo-600 bg-white hover:bg-indigo-50 cursor-pointer">
                                {t('notificationbar.button')}
                            </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}