import { useTranslation } from 'react-i18next';

export default function Listing({ entity, advert }) {
  const { t } = useTranslation();

  return (
    <>
      <a
        href={
          advert ? "https://upgrade-estate.be/nl/investeren/studentenkamer-appartement" 
          : entity.type && entity.city && entity.street && entity.number && entity.nid 
          ? "/" +
          entity.type +
          "/" +
          entity.city +
          "/" +
          entity.street + "_" + entity.number +
          "/" +
          entity.nid
          : "/404/"
        }
        target="_blank"
      >
        <li
          className={
            advert
              ? "bg-blue-400 col-span-1 rounded-lg shadow hover:shadow-md"
              : "bg-white col-span-1 rounded-lg shadow hover:shadow-md"
          }
        >
          <img
            src={
              advert
                ? process.env.REACT_APP_BACKEND_URL + "themes/custom/studentroom/static_images/advert-listing.png"
                : entity.photo ? process.env.REACT_APP_BACKEND_URL + entity.photo[0] 
                : process.env.REACT_APP_BACKEND_URL + "themes/custom/studentroom/static_images/listing-placeholder.png"
            }
            className="img-responsive rounded-tl-lg rounded-tr-lg object-cover w-full h-44"
          />
          {!advert ? (
            <>
              <div className="w-full flex items-center justify-between p-3 space-x-6">
                <div className="flex-1 truncate">
                  <div className="flex flex-wrap items-center space-x-3">
                    <h4 className="text-gray-500 text-sm uppercase">
                      {entity.city}
                    </h4>
                    <h5 className="text-gray-400 text-xs capitalize truncate">
                      {entity.street} {entity.number}
                    </h5>
                  </div>
                  <h3
                    className="text-indigo-600 text-xl font-medium capitalize truncate"
                    style={{ "margin-left": 0 }}
                  >
                    {entity.title}
                  </h3>
                </div>
              </div>
              <div className="flex text-center border-t">
                <div className="w-full border-r pt-2 pb-2">
                  <h4 className="text-gray-400 text-xs uppercase">{t('listings.listing.price')}</h4>
                  <h3 className="font-medium text-indigo-600 text-lg capitalize">
                    € {entity.price}
                  </h3>
                </div>
                <div className="w-full pt-2">
                  <h4 className="text-gray-400 text-xs uppercase">
                    {t('listings.listing.size')}
                  </h4>
                  <h3 className="font-medium text-indigo-600 text-lg">
                    {entity.size ? entity.size : "?"} m<sup>2</sup>
                  </h3>
                </div>
              </div>
            </>
          ) : (
            <div className="text-center text-white w-2/3 mx-auto py-3 rounded-lg">
              {/* <h3 className="text-xl font-medium truncate font-bold">{t('listings.advert.title')}</h3> */}
              <p className="mt-1 mb-5 text-sm">{t('listings.advert.description')}</p>
              <div className="mb-2">
              <span className="text-black bg-white p-2 rounded-md text-sm font-bold pl-3 pr-3">
                {t('listings.advert.button')}
              </span>
              </div>
            </div>
          )}
        </li>
      </a>
    </>
  );
}
