// import packages
import { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useRecoilState } from "recoil";
import { useTranslation } from "react-i18next";

//import components
import Spacer from "../components/Spacer.component";
import MainMenu from "../components/MainMenu.component";
import HomeSearch from "../components/HomeSearch.component";
import ImageCard from "../components/ImageCard.component";
import Footer from "../components/Footer.component";
import { ReactComponent as HomeLandlord } from "../assets/img/home-landlord.svg";

//import datastores
import { cityState } from "../data/city.atom";

//import services (?)
import CityService from "../services/cities.service";

export default function HomeView() {
  const { t, i18n } = useTranslation();

  const slider = {
    infinite: true,
    speed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    draggable: false,
    fade: true,
    pauseOnHover: false,
    swipe: false,
    touchMove: false,
    arrows: false,
  };

  const [sliderContent, setSliderContent] = useState();
  const [cities, setCities] = useRecoilState(cityState);

  useEffect(() => {
    CityService.getSlider()
      .then((response) => {
        setSliderContent(response.data[0]);
      })
      .catch((error) => {});

    CityService.getCities()
      .then((response) => {
        setCities(response.data);
      })
      .catch((error) => {});
    document.title = t("home.title");
  }, [i18n.language]);

  return (
    <>
      <MainMenu />

      {sliderContent ? (
        <Slider {...slider}>
          {sliderContent.map((image) => (
            <img
              key={image}
              src={process.env.REACT_APP_BACKEND_URL + image}
              className="object-cover w-auto h-screen"
            />
          ))}
        </Slider>
      ) : (
        <></>
      )}

      <HomeSearch />

      <div className="bg-gray-100">
        <Spacer height={80} />
        <div className="w-3/4 mx-auto">
          <h1 className="text-2xl text-gray-600 font-medium">
            {t("home.popularcities")}
          </h1>

          <div className="flex flex-wrap">
            {cities ? (
              <>
                {cities.map((city) =>
                  city.show_on_homepage == 1 ? (
                    <ImageCard
                      title={city.name}
                      subtext={city.number_of_listings}
                      image={
                        city.media
                          ? process.env.REACT_APP_BACKEND_URL + city.media[0]
                          : ""
                      }
                    />
                  ) : (
                    <></>
                  )
                )}
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
        <Spacer height={80} />
      </div>

      <div className="bg-gray-200">
        <Spacer height={80} />
        <div className="max-w-sm mx-auto text-center">
          <HomeLandlord className="mx-auto mb-5" />
          <h1 className="text-2xl text-gray-600 font-medium">
            {t("home.advert.title")}
          </h1>
          <p className="pt-5 text-gray-500 text-xl font-light">
            {t("home.advert.description")}
          </p>
        </div>
        <Spacer height={80} />
      </div>

      <Footer />
    </>
  );
}
